.networkErr {
    align-items: center;
    display: flex;
    background: var(--primary-linear-gradient-v2);
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.networkErr__img--wrap {
    height: 200px;
    left: 50%;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    z-index: 2;
}

.networkErr__img {
    height: 100%;
    width: 100%;
}

.networkErr__title {
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 0;
}
.networkErr__text {
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
}

.networkErr__btn {
    color: #fff;
    background-color: #1aa086;
    border: none;
    border-radius: 15px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-regular);
    font-size: 1rem;
    outline: none;
    padding: 12px 16px;
    width: 120px;
    z-index: 10;
}
