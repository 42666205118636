.assocGroup__header {
    align-items: center;
    border-bottom: solid 1px rgb(212, 212, 212);
    display: flex;
    padding-top: 50px;
    padding-bottom: 25px;
    width: 100%;
}
​ .assocGroup__header--logo--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
}
​ .assocGroup__header--logo {
    width: 48px;
    height: 50px;
    object-fit: contain;
}
​
/* landing title */
.assocGroup__content--container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.assocGroup__text--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 12px 16px;
    padding-bottom: 30px;
    height: 70vh;
    width: 100%;
}
.assocGroup__text--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
}
.assocGroup__text--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
    margin-top: 20px;
}
.assocGroup__title--span {
    color: var(--primary-color-green);
}
.assocGroup__content--btn {
    background: var(--primary-linear-gradient-v2);
    border-radius: 15px;
    color: #fff;
    padding: 12px 16px;
    width: 70%;
}
​
/* policy */
.assocGroup__policy--link--container {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    width: 100%;
}
.assocGroup__policy--link--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    width: 100%;
}

.assocGroup__policy--link {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
}

/* divider */
.assocGroup__typeDivider {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    width: 100%;
}
.assocGroup__typeDivider__text {
    border-bottom: 1px solid var(--primary-color-inactive-gray);
    color: var(--primary-color-inactive-gray);
    line-height: 0.1em;
    text-align: center;
}
.assocGroup__typeDivider__text--span {
    background: #fff;
    padding: 0 10px;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
