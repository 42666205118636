ion-grid {
    padding: 0;
}
/* back btn */
.skillSelection__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 24px 16px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
}
.skillSelection__close--btn {
    color: var(--primary-color-black);
}
.skillSelection__btn--icon {
    color: black;
    font-size: 2rem;
}

/* signup header */
.skillSelection__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.skillSelection__header--container {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    position: relative;
}

.skillSelection__header--strokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}

/* skillSelection text content */
.skillSelection__textContent {
    font-family: var(--ion-font-poppins-family);
    background-color: #fff;
}
.skillSelection__textContent--container {
    padding: 0 30px;
    text-align: center;
}
.skillSelection__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.skillSelection__textContent--title span {
    /* color: var(--primary-color-green); */
    color: #10b080;
}
.skillSelection__textContent--text {
    color: var(--primary-color-black);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
}

/* search bar */
.skillSelection__searchbar {
    background-color: #fff;
    text-align: center;
}
.skillSelection__searchbar--container {
    align-items: center;
    display: flex;
    padding: 0 30px;
    padding-bottom: 15px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.skillSelection__searchbar--input--wrap {
    background-color: #fff;
    border: none;
    position: relative;
    width: 90%;
    z-index: 10;
}
.skillSelection__searchbar--input {
    color: var(--primary-color-black);
    background-color: #f6f6f6;
    border: none;
    border-radius: 8px;
    outline: none;
    padding: 12px 16px;
    padding-left: 30px;
    width: 100%;
}
.skillSelection__searchbar--input::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    padding-left: 5px;
}
.skillSelection__searchbar--input--icon {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    top: 24px;
    left: 20px;
    position: absolute;
}

/* skill selection list */
.skillSelection__list {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 70px;
    text-align: center;
}
.skillSelection__list--title--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}
.skillSelection__list--title {
    color: var(--primary-color-black);
    font-size: var(--xsmall-text);
    padding: 0;
    margin: 0;
}

.skillSelection__list--item {
    background-color: #f6f6f6;
    color: black;
    border-radius: 10px;
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    height: 50px;
    margin-bottom: 10px;
    width: 70%;
    z-index: 10;
}

/* copyright */
.skillSelection__footer--strokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
}
