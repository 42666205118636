ion-grid {
    padding: 0;
}

/* page containers */
.taskConfirm__grid {
    height: 100vh;
}
.taskConfirm {
    background: var(--primary-linear-gradient-v2);
    color: #fff;
    height: 100%;
    width: 100%;
}
.taskConfirm__btn {
    color: #fff;
}
.taskConfirm__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0 30px;
    text-align: center;
}
.taskConfirm__back--btn {
    position: absolute;
    left: 11%;
    top: 5%;
    color: black;
}

.taskConfirm__strokes--top {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.taskConfirm__strokes--bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
}

/* task confirm content */
.taskConfirm__img--wrap {
    border: solid 5px #fff;
    border-radius: 15px;
    height: 105px;
    overflow: hidden;
    padding: 0;
    width: 105px;
    z-index: 2;
}
.taskConfirm__img {
    height: 100%;
    width: 100%;
    z-index: 2;
}

.taskConfirm__title {
    color: var(--primary-color-white);
    border-bottom: none;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    z-index: 2;
}
.taskConfirm__text {
    border-bottom: none;
    color: var(--primary-color-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
    margin: 0;
    z-index: 2;
}
.goHome_button {
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: underline;
}
