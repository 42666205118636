ion-grid {
    padding: 0;
}

.settings__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.settings__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.settings__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}

.settings__textContent {
    background-color: #fff;
}
.settings__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 15px;
    padding-top: 50px;
    text-align: center;
}
.settings__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.settings__textContent--title span {
    color: var(--primary-color-green);
}

.settings__form--container {
    background-color: #fff;
    padding: 0 15px;
    padding-bottom: 80px;
}
.settings__form--row {
    border-radius: 15px;
    background-color: #f3f5f7;
    padding: 10px 10px;
    width: 100%;
}
.settings__col--wrap {
    background-color: #fff;
    border-radius: 15px;
    padding: 5px 5px;
    width: 100%;
}
.settings__col {
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.settings__content {
    align-items: center;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    padding: 15px 5px;
    position: relative;
    text-align: center;
}

.settings__content--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}
.settings__content--description {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}

/* createTask btn */
.settings__btn--container {
    background-color: #fff;
    width: 100%;
}
.settings__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 60px;
    z-index: 10;
}
.settings__btn {
    background: var(--primary-linear-gradient-v2);
    border-radius: 12px;
    color: #fff;
    width: 80%;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
}

/* settings section divider */
.settings__typeDivider {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    width: 100%;
}
.settings__typeDivider__text {
    border-bottom: 1px solid var(--primary-color-inactive-gray);
    color: var(--primary-color-inactive-gray);
    line-height: 0.1em;
    text-align: center;
}
.settings__typeDivider__text--span {
    background-color: #fff;
    padding: 0 10px;
}

/* timezone container */
.settings__tz--col {
    border-radius: 15px;
    background-color: #f3f5f7;
}
.settings__tz--wrap {
    border-radius: 12px;
    border: none;
}
.settings__tz--label {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: 300;
}
.settings__tz--select {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    color: var(--primary-color-dark-green);
}
