ion-grid {
    padding: 0;
}

/* signup header */
.manageReq__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.manageReq__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.manageReq__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.manageReq__header--img {
    border-radius: 15px;
    border: solid 4px #fff;
    height: 120px;
    width: 120px;
    z-index: 10;
}
.manageReq__back--btn {
    position: absolute;
    left: 11%;
    top: 10%;
    color: #fff;
}

/* skillSelection text content */
.manageReq__textContent {
    background-color: #fff;
}
.manageReq__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 2px;
    padding-top: 50px;
    text-align: center;
}
.manageReq__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.manageReq__textContent--title span {
    color: var(--primary-color-green);
}
.manageReq__textContent--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
}

/* manageReq request item */
.manageReqItem__row {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}
.manageReqItem__row:last-child {
    padding-bottom: 10px;
}
/* .manageReqItem__container:last-child {
    padding-bottom: 10px;
} */
/* .manageReqItem__container {
    border-bottom: solid 1px var(--primary-color-green);
    margin: 0;
    padding: 0;
} */
.manageReqItem__tag--container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-top: 10px;
    padding: 0px 0px 10px 0px;
    border-bottom: solid 1px var(--primary-color-green);
}
.manageReqItem__tag--wrap {
    align-items: center;
    border: none;
    border-radius: 15px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}
.manageReqItem__tag--name {
    color: var(--primary-color-purple);
    border-radius: 15px;
    background-color: #f3f5f7;
    display: inline-block;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 6px;
}
.manageReqItem__tag--icon {
    color: var(--primary-color-green);
    font-size: 1.2rem;
    margin-left: 10px;
}

.manageReqItem__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    margin: 0;
    padding: 0;
    padding-left: 15px;
}

.manageReqItem {
    align-items: flex-start;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    position: relative;
}

.manageReqItem__info {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.manageReqItem__img--wrap {
    border: none;
    border-radius: 15px;
    height: auto;
    padding: 0;
    margin-left: 5px;
    position: absolute;
    left: -10px;
    top: -50px;
    width: 75px;
    overflow: hidden;
}
.manageReqItem__img {
    border: solid 4px #fff;
    border-radius: 15px;
    height: auto;
    overflow: hidden;
    width: 100%;
}
.manageReqItem__info--name {
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 0;
}
.manageReqItem__info--skill {
    color: var(--primary-color-purple);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}
.manageReqItem__info--skill span {
    color: var(--primary-color-green);
}
.manageReqItem__btn--wrap {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.manageReqItem__btn--accept,
.manageReqItem__btn--cancel {
    border-radius: 8px;
    width: 50%;
}
.manageReqItem__btn--cancel {
    background-image: linear-gradient(106deg, #ce5959, #f56666);
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
}
.manageReqItem__btn--accept {
    background: var(--primary-linear-gradient-v2);
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
}

.manageReqItem__fallback {
    background-color: #fff;
    border: none;
    text-align: center;
}
.manageReqItem__fallback--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-regular);
}

/* participants */
/* tp details participants */
.manageReq__users--container {
    background-color: #fff;
    padding-bottom: 30px;
    width: 100%;
}
.manageReq__users {
    align-items: center;
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.manageReq__users--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}

.manageReq__user {
    align-items: center;
    background-color: #fff;
    border: solid 4px #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
}
.manageReq__user--img {
    display: flex;
    border-radius: 15px;
    height: 65px;
    width: 65px;
    overflow: hidden;
}
.manageReq__user--name {
    margin: 0;
    padding: 5px 0;
}

.manageReq__users--fallback {
    border: none;
    text-align: center;
}
.manageReq__users--fallback--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-regular);
}
