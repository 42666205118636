.taskPartc__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.taskPartc__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.taskPartc__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.taskPartc__header--img {
    border: solid 6px #fff;
    border-radius: 15px;
    height: 120px;
    width: 120px;
    z-index: 10;
}

/* taskPartc text content */
.taskPartc__textContent {
    background-color: #fff;
}
.taskPartc__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 15px;
    padding-top: 50px;
    text-align: center;
}
.taskPartc__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.taskPartc__textContent--title span {
    color: var(--primary-color-green);
}

/* taskPartc Item */
.taskPartcItem__row {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    width: 100%;
}
.taskPartcItem__row:last-child {
    padding-bottom: 10px;
}

.taskPartcItem__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
    padding-left: 15px;
}

.taskPartcItem {
    align-items: flex-start;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    padding-top: 30px;
    position: relative;
}

.taskPartcItem__content {
    background-color: #fff;
    border-radius: 15px;
}

.taskPartcItem__info {
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
}
.taskPartcItem__img--wrap {
    border: none;
    border-radius: 15px;
    height: auto;
    padding: 0;
    margin-left: 5px;
    position: absolute;
    left: -10px;
    top: -50px;
    width: 75px;
    overflow: hidden;
}
.taskPartcItem__img {
    border: solid 4px #fff;
    border-radius: 15px;
    height: auto;
    overflow: hidden;
    width: 100%;
}
.taskPartcItem__info--name {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 0;
}
.taskPartcItem__info--cred {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}

/* task participants btns */

.taskPartcItem__btn--wrap {
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.taskPartcItem__btn--cred {
    background: linear-gradient(to right, #6c44fe 1%, #8c6df9);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    width: 50%;
}
.taskPartcItem__btn--remove {
    background: linear-gradient(106deg, #ce5959, #f56666 100%);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    width: 50%;
}

.taskPartc__btn--remove {
    background: linear-gradient(106deg, #ce5959, #f56666 100%);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    width: 80%;
}

/* add new participant btn */
.taskPartc__btn--container {
    align-items: center;
    display: flex;
    background-color: #fff;
    justify-content: center;
    width: 100%;
}
.taskPartc__btn--wrap {
    align-items: center;
    border: solid 4px #f3f5f7;
    border-radius: 15px;
    color: var(--primary-color-green);
    display: flex;
    justify-content: space-between;
    padding: 0px 12px;
    width: 80%;
    z-index: 10;
}
.taskPartc__btn--text {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}
