.onboardingC__header {
    align-items: center;
    border-bottom: solid 1px rgb(212, 212, 212);
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 100%;
}
​ .onboardingC__header--logo--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
}
​ .onboardingC__header--logo {
    width: 48px;
    height: 50px;
    object-fit: contain;
}

/* confirmation title */
.onboardingC__title--row {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
}
.onboardingC__title--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 12px 16px;
}
.onboardingC__text--title {
    font-family: var(--ion-font-poppins-family);
    font-size: 2.2rem;
    font-weight: 700;
    margin-top: 30px;
}
.onboardingC__title--span {
    color: var(--primary-color-green);
}

/* confirmation text */
.onboardingC__text--row {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
}
.onboardingC__text--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 12px 16px;
    position: relative;
}
.onboardingC__text {
    font-family: var(--ion-font-poppins-family);
    font-size: 1rem;
    font-weight: 300;
    padding: 0;
    overflow-wrap: normal;
    margin-top: 20px;
}
.onboardingC__text--span {
    color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-size: 1rem;
    font-weight: 700;
    padding: 0;
    margin-top: 20px;
}
.onboardingC__text--break {
    color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-size: 1rem;
    font-weight: 700;
    overflow-wrap: break-word;
    padding: 0;
    margin-top: 20px;
}

.onboardingC__content--btn {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    outline: none;
    text-align: center;
    z-index: 99;
    width: 100%;
}

/* policy */
.onboardingPolicy__content--row {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}
.onboardingPolicy__content--col {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.onboardingPolicy__links {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 50px;
}
.onboardingC__policy--link {
    color: black;
}
