.skillsOffered__col {
    align-items: flex-start;
    background-color: #f3f5f7 !important;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 10px;
}

.skillsOffered__content {
    align-items: flex-start;
    background-color: #fff !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-align: left;
}

.skillsOffered__content--skill {
    color: black;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}

.skillsOffered__content--keyword {
    color: var(--primary-color-dark-green);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}

.skillsOffered__content--completed {
    color: var(--primary-color-purple);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
}
.skillsOffered__content--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}

.skillsOffered__request--btn {
    align-items: center;
    background-color: #fff !important;
    border-left: solid 1px #f3f5f7 !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-radius: none;
    color: var(--primary-color-dark-green);
    display: flex;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    height: 100%;
    margin: 0;
    outline: none;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    text-align: center;
    width: 100%;
}

.skillsOffered__request--btn--text,
.skillsOffered__request--btn--name {
    margin: 0;
    padding: 0;
}
