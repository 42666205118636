.taskPartcItem__review {
    align-items: center;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
}
/* star-review system */
input[type='radio'] {
    display: none;
}

.taskPartcStars__container {
    align-items: center;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
}
.taskPartcStars__icon {
    color: var(--primary-color-light-gray);
    cursor: pointer;
    font-size: 1.4rem;
    transition: color 200ms;
    margin-right: 5px;
}

/* review textarea */

.taskPartcReview__textArea--wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    border: solid 5px var(--primary-color-black);
}

.taskPartcReview__textarea {
    background-color: #fff;
    border: none;
    border-radius: 8px;
    height: 120px;
    outline: none;
    padding: 12px 12px;
    resize: none;
    width: 100%;
}

.taskPartcReview__form--textarea::placeholder {
    color: rgba(40, 41, 42, 0.3);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}
