.btn {
    margin: -8px;
    margin-top: 35px;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    background-color: transparent;
}

.icon {
    color: white;
    font-size: 3rem;
}
