ion-grid {
    padding: 0;
}

/* page containers */
.joinGroup__grid {
    background-color: #fff;
}
.joinGroup {
    height: 100vh;
    width: 100%;
}

/* strokes */
.joinGroup__strokes--top {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
}
.joinGroup__strokes--bottom {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
}

.joinGroup__container {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 0;
    text-align: center;
}
.joinGroup__img {
    padding-bottom: 30px;
    height: 100px;
    width: 100px;
}
.joinGroup__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
}
.joinGroup__title--span {
    /* color: var(--primary-color-green); */
    color: #10b080; /* zeplin green */
}
.joinGroup__text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}

.joinGroup__form--wrap {
    margin: 0;
    padding: 0;
}
.joinGroup__form {
    align-items: center;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.joinGroup__form--input {
    background-color: #f6f6f6; /* zeplin input bg-color */
    border: none;
    border-radius: 8px;
    color: var(--primary-color-black);
    outline: none;
    padding: 12px 16px;
    margin-bottom: 25px;
    width: 80%;
    z-index: 99;
}
.joinGroup__form--input::placeholder {
    color: rgba(40, 41, 42, 0.2); /* zeplin input placeholder-color */
    font-size: var(--xsmall-text);
    font-weight: var(--font-semi-bold);
}

.joinGroup__form--submit {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    z-index: 99;
    width: 80%;
}

.joinGroup__btn--confirm {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    margin-top: 25px;
    outline: none;
    text-align: center;
    z-index: 99;
    width: 80%;
}

/* ! error handling */
.joinGroup__error--container {
    align-items: center;
    background-color: #fff;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 100%;
}
.joinGroup__error--col {
    text-align: center;
    margin: 0;
    padding: 15px 0;
}

.joinGroup__form--input--error {
    border: solid 1px var(--primary-color-red);
}
.joinGroup__error {
    color: var(--primary-color-red);
    font-size: var(--xsmall-text);
    font-weight: 600;
    padding: 0;
    margin: 0;
}
.joinGroup__error--span {
    color: var(--primary-color-red);
    font-size: var(--xsmall-text);
    font-weight: 300;
}
.joinGroup__error--underline {
    border-bottom: solid 1px var(--primary-color-red);
}
