.flowNav__btns--wrap {
    align-items: center;
    display: flex;
    position: absolute;
    justify-content: space-between;
    left: 0;
    padding: 25px 10px;
    top: 0;
    width: 100%;
}
.flowNav__btn {
    z-index: 10;
}
.flowNav__btn--icon {
    color: #fff;
    font-size: 2.2rem;
}
