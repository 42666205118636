ion-grid {
    padding: 0;
}

/* signup header */
.taskLocation__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.taskLocation__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.taskLocation__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.taskLocation__header--img {
    border-radius: 15px;
    border: solid 4px #fff;
    height: 120px;
    width: 120px;
    z-index: 10;
}

/* skillSelection text content */
.taskLocation__textContent {
    background-color: #fff;
}
.taskLocation__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 15px;
    padding-top: 50px;
    text-align: center;
}
.taskLocation__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.taskLocation__textContent--title span {
    color: var(--primary-color-green);
}
.taskLocation__textContent--text {
    color: var(--primary-color-light-white);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
}

/* skill definition form */
.taskLocation__form--container {
    background-color: #fff;
    padding: 0 15px;
    padding-bottom: 30px;
}
.taskLocation__form--row {
    border-radius: 8px;
    background-color: #f3f5f7;
    padding: 10px 10px;
    width: 100%;
}
.taskLocation__form--input--wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
.taskLocation__form--label {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: 0.75rem;
    font-weight: var(--font-regular);
    margin-bottom: 5px;
    margin-left: 5px;
}
.taskLocation__form--input {
    color: var(--primary-color-black);
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    outline: none;
    width: 100%;
}
.taskLocation__form--input::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}

/* form btns */
.taskLocation__form--btns--container {
    align-items: center;
    display: flex;
    flex-direction: row;
}
.taskLocation__form--btn {
    background-color: #fff;
    border: none;
    border-radius: 8px;
    color: var(--primary-color-green);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    width: 100%;
}

/* createTask btn */
.taskLocation__btn--container {
    background-color: #fff;
    width: 100%;
}
.taskLocation__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    z-index: 10;
}
.taskLocation__btn {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    width: 80%;
}

/* skill definition form */
.taskLocation__form--container {
    background-color: #fff;
    padding: 0 15px;
    padding-bottom: 80px;
}
.taskLocation__form--row {
    border-radius: 8px;
    background-color: #f3f5f7;
    padding: 10px 10px;
    width: 100%;
}
.taskLocation__form--row--title {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
    padding: 5px 0;
    text-align: center;
    width: 100%;
}
.taskLocation__col {
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    /* margin-bottom: 10px; */
}

.taskLocation__content {
    align-items: center;
    background-color: #f3f5f7;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-align: center;
}
.taskLocation__content--active {
    background-color: rgba(16, 176, 128, 0.205);
}

.taskLocation__content--place {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}
.taskLocation__content--place--active {
    color: var(--primary-color-green);
}
.taskLocation__content--address {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}
.taskLocation__content--address--active {
    color: var(--primary-color-green);
}

/* location tyoe divider */
/type divider / .taskLocation__typeDivider {
    align-items: center;
    background-color: #f3f5f7;
    display: flex;
    justify-content: center;
    width: 100%;
}
.taskLocation__typeDivider__text {
    border-bottom: 1px solid var(--primary-color-inactive-gray);
    color: var(--primary-color-inactive-gray);
    line-height: 0.1em;
    text-align: center;
}
.taskLocation__typeDivider__text--span {
    background-color: #f3f5f7;
    padding: 0 10px;
}

.defSkill__form--input--wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
.taskLocation__form--label {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: 0.75rem;
    font-weight: var(--font-regular);
    margin-bottom: 5px;
    text-align: center;
    width: 100%;
}
.taskLocation__form--input {
    color: var(--primary-color-black);
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    outline: none;
    width: 100%;
}
.taskLocation__form--input::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    text-align: center;
}
