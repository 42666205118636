ion-grid {
    padding: 0;
}

/* page containers */
.delJobPost__grid {
    height: 95vh;
}
.delJobPost {
    background: var(--primary-linear-gradient-v2);
    height: 100%;
    width: 100%;
}
.delJobPost__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0 30px;
    text-align: center;
}
.delJobPost__back--btn {
    position: absolute;
    left: 11%;
    top: 5%;
    color: black;
}

.delJobPost__strokes--top {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.delJobPost__strokes--bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
}

/* task confirm content */
.delJobPost__img--wrap {
    border: solid 6px #fff;
    border-radius: 15px;
    height: 105px;
    overflow: hidden;
    padding: 0;
    width: 105px;
    z-index: 2;
}
.delJobPost__img {
    height: 100%;
    width: 100%;
}

.delJobPost__title {
    border-bottom: none;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    z-index: 2;
}
.delJobPost__text {
    border-bottom: none;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
    margin: 0;
    z-index: 2;
}

.delJobPost__text-color {
    text-decoration: underline;
}
