ion-grid {
    padding: 0;
}

/* search bar & header */
.genMemberSkillSearch-ionSearchBar {
    --color: var(--primary-color-green);
    --background: #f6f6f6;
    --placeholder-color: var(--primary-color-inactive-gray);
    --icon-color: var(--primary-color-inactive-gray);
    --clear-button-color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}

.genMemberSkillSearch-ionSearchBar .searchbar-clear-icon {
    display: none !important;
}

.genMemberSkillSearch-header {
    padding-top: 18px;
}

.genMemberSkillSearch-toolbar {
    padding-bottom: 16px;
}

.genMemberSkillSearch__close--btn {
    color: var(--primary-color-green);
    margin-right: 15px;
    margin-left: 0px;
    font-size: 2rem;
}

.genMemberSkillSearch__container {
    /* background-color: #f3f5f7; */
    /* padding-top: 120px; */
}

.genMemberSkillSearch__searchbar {
    background-color: #fff;
    box-shadow: 0px 0px 8px 0px rgba(26, 26, 26, 0.25);
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}
.genMemberSkillSearch__searchbar--container {
    align-items: center;
    display: flex;
    /* padding: 0 30px; */
    padding-bottom: 15px;
    padding-top: 40px;
    padding-left: 10px;
    text-align: center;
}
.genMemberSkillSearch__searchbar--input--wrap {
    background-color: #fff;
    border: none;
    position: relative;
    width: 100%;
    z-index: 10;
}
.genMemberSkillSearch__searchbar--input--icon {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    top: 24px;
    left: 20px;
    position: absolute;
}
.genMemberSkillSearch__searchbar--input {
    color: var(--primary-color-green);
    background-color: #f6f6f6;
    border: none;
    border-radius: 8px;
    outline: none;
    padding: 12px 16px;
    padding-left: 30px;
    width: 100%;
}
.genMemberSkillSearch__searchbar--input::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    padding-left: 5px;
}

/* section title */
.genMemberSkillSearch__sectionTitle {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}
.genMemberSkillSearch__sectionTitle--wrap {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 0 25px;
    text-align: left;
}
.genMemberSkillSearch__sectionTitle--text {
    border-bottom: none;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
}
.genMemberSkillSearch__sectionTitle--skillName {
    color: var(--primary-color-green);
}
.genMemberSkillSearch__sectionTitle--text span {
    color: var(--primary-color-green);
    border-bottom: solid 1px var(--primary-color-green);
}

/* new members list */
.genMemberSkillSearch__newMembers--container {
    background: none;
    background-color: none;
    padding: 0 20px;
    width: 100%;
}
.genMemberSkillSearch__newMembers--list {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    position: relative;
    width: 100%;
}
.genMemberSkillSearch__newMembers--item {
    align-items: flex-start;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    height: 250px;
    flex-direction: column;
    justify-content: flex-end;
    margin: 5px;
    padding: 12px 16px;
    width: 400px;
    max-width: 300px;
}
.genMemberSkillSearch__newMembers--name {
    color: #fff;
    border-bottom: none;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    margin: 0;
    margin-bottom: 2px;
    padding: 0;
}
.genMemberSkillSearch__newMembers--text {
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: x-small;
    font-weight: var(--font-semi-bold);
    margin: 0;
    margin-bottom: 2px;
    padding: 0;
}
.genMemberSkillSearch__newMembers--reviews {
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: x-small;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

/* nearby members */
.genMemberSkillSearch__nearbyMembers--container {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 20px 20px 0px 10px;
    width: 100%;
}
.genMemberSkillSearch__nearbyMembers--title {
    border-bottom: none;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    margin: 0;
    padding: 0;
}
.genMemberSkillSearch__nearbyMembers--title span {
    color: var(--primary-color-green);
    border-bottom: solid 1px var(--primary-color-green);
}

.genMemberSkillSearch__nearbyMembers--text {
    border-bottom: solid 1px var(--primary-color-green);
    color: var(--primary-color-green);
    display: inline-block;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: 700;
    margin: 0;
    padding: 0;
}
.genMemberSkillSearch__nearbyMembers--btn {
    color: var(--primary-color-green);
    border-radius: 8px;
    background-color: #f3f5f7;
}
