.tasksFallback {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0;
    text-align: center;
    padding-bottom: 30px;
    width: 100%;
}

.tasksFallback__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular-bold);
    margin: 0;
    padding-bottom: 0px;
}

.tasksFallback__title_empty {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular-bold);
    margin: 0;
    padding-bottom: 40px;
}

.tasksFallback__text {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}

.tasksFallback__btn {
    border-radius: 8px;
    color: var(--primary-color-white);
    background: var(--primary-linear-gradient-v2);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin-top: 100px;
    width: 75%;
}
