ion-grid {
    padding: 0;
}

/* signup header */
.startTime__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.startTime__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.startTime__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.startTime__header--img {
    border: solid 6px #fff;
    border-radius: 15px;
    height: 120px;
    width: 120px;
    z-index: 10;
}

/* skillSelection text content */
.startTime__textContent {
    background-color: #fff;
}
.startTime__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 16px;
    padding-top: 50px;
    text-align: center;
}
.startTime__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.startTime__textContent--title span {
    color: var(--primary-color-green);
}
.startTime__textContent--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
}

/* skill definition form */
.startTime__form--container {
    background-color: #fff;
    padding: 0 15px;
    padding-bottom: 30px;
}
.startTime__form--row {
    border-radius: 15px !important;
    background-color: #f3f5f7;
    padding: 10px 10px;
    width: 100%;
}
.startTime__form--input--wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
.startTime__form--label {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: 0.75rem;
    font-weight: var(--font-regular);
    margin-bottom: 5px;
    margin-left: 5px;
}
.startTime__form--input {
    color: var(--primary-color-black);
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    outline: none;
    width: 100%;
}
.startTime__form--input::placeholder {
    color: rgba(169, 169, 169, 0.822);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}

/* form btns */
.startTime__form--btns--container {
    align-items: center;
    display: flex;
    flex-direction: row;
}
.startTime__form--btn {
    background-color: #fff;
    border: none;
    border-radius: 8px;
    color: var(--primary-color-green);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    width: 100%;
}

/* createTask btn */
.startTime__btn--container {
    background-color: #fff;
    width: 100%;
}
.startTime__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 0px !important;
    padding-top: 5px !important;
    z-index: 10;
}
.startTime__btn {
    color: #fff;
    background: var(--primary-linear-gradient-v2);
    border-radius: 15px;
    width: 80%;
}

/* skill definition form */
.startTime__form--container-tbd {
    background-color: #f3f5f7;
    padding: 10px 10px;
    margin-top: -10px;
}
.startTime__form--row {
    align-items: center;
    border-radius: 10px;
    background-color: #f3f5f7;
    display: flex;
    padding: 10px 10px;
    justify-content: center;
    text-align: center;
    width: 100%;
}
.startTime__form--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
    padding: 10px 24px;
}
.startTime__form--text span {
    color: var(--primary-color-green);
    border-bottom: solid 1px var(--primary-color-green);
}
.startTime__col {
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 10px;
    padding: 0;
}
.startTime__content {
    align-items: center;
    background-color: #fff;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.startTime__content--day {
    align-items: center;
    background-color: #f3f5f7;
    border-radius: 10px;
    color: var(--primary-color-black);
    display: flex;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-bold);
    height: 45px;
    justify-content: center;
    text-align: center;
    margin: 4px;
    width: 22.13%;
}
.startTime__form--row-tbd {
    margin-top: -10px;
    border-radius: 15px;
    padding-top: 0px;
    background-color: #f3f5f7;
    padding: 5px;
    width: 100%;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.startTime__content--day--na {
    align-items: center;
    background-color: #ffffff;
    border: none;
    border-radius: 15px;
    color: var(--primary-color-black);
    display: flex;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-bold);
    height: 45px;
    justify-content: center;
    text-align: center;
    margin: 4px;
    width: 100%;
}
.startTime__content--day--active {
    background-color: rgba(16, 176, 128, 0.205);
    color: var(--primary-color-green);
}

/* startTime dials */
.startTime__dials--row {
    padding: 0 16px;
    width: 100%;
}
.startTime__dials--label {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-semi-bold);
}
.startTime__dials--col {
    align-items: center;
    border-bottom: solid 1px #f3f5f7;
    display: flex;
    /* justify-content: space-between; */
}

/* startTime/endTime dials msg */
.startTime__dials--msg--wrap {
    padding: 0 64px;
    width: 100%;
}
.startTime__dials--msg {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}
.startTime__dials--msg--na {
    color: var(--primary-color-red);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}
.startTime__dials--msg--avl {
    color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}
.startTime__dials--msg--avl--icon {
    color: var(--primary-color-green);
    font-size: 1rem;
}

/* Time Tiles Styling */

.selected {
    background-color: #10b08034;
    color: var(--primary-color-green);
}

.arrow-left {
    background-color: inherit;
    margin-right: 3px;
    padding-right: 9.5px;
    padding-left: 0px;
    padding-bottom: 0.9rem;
}

.arrow-right {
    background-color: inherit;
    margin-left: 3px;
    padding-left: 9.5px;
    padding-right: 0px;
    padding-bottom: 0.9rem;
}
.blue {
    font-weight: 700;
    background-color: inherit;
    font-size: small;
    margin: 0.35rem 0.2rem;
    padding-bottom: 0.9rem;
}

.calendar-button button {
    border-radius: 15px;
    margin: 0.6rem 0.237rem;
    padding: 0.1rem 0.85rem;
    font-weight: 700;
    font-size: small;
    grid-row: 1;
    font-family: 'Poppins', sans-serif;
    white-space: nowrap;
    max-width: 250px;
}

.calendar-button {
    height: 65px;
    overflow-x: scroll;
    margin-top: 0rem;
    display: grid;
    border-radius: 15px;
}

.calendar-time-title {
    margin: 1rem 0rem 0.35rem 1.5rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 19px;
}

.white {
    background-color: #fff;
    padding: 0 0px;
}

.day-picker {
    border: 1rem solid #f3f5f7;
    background-color: #f3f5f7 !important;
    margin-bottom: 0rem !important;
    border-radius: 0px !important;
    border-bottom: thin;
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
}

.height {
    margin: 1rem;
    border-radius: 0px !important;
    font-family: 'Poppins', sans-serif;
    margin-top: 5px;
}

.react-calendar__navigation button[disabled] {
    color: black;
    font-size: 25px;
    font-weight: 100;
}

.unavailable {
    pointer-events: none;
}

.tbd-button {
    margin-top: 1rem !important;
}

.duration {
    margin: 1rem;
    border-radius: 15px !important;
}

.duration-time-title {
    margin-left: 1.5rem;
    margin-bottom: 1.3rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 19px;
}

.duration-text {
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: 21px;
}
.duration-button {
    width: 90px;
    padding: 0 0.5px;
}

.end-time-title {
    margin-left: 1.5rem;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 21px;
}

.duration-button:selected {
    background-color: rgba(16, 176, 128, 0.205);
    color: var(--primary-color-green);
}

.portfolio-buttons {
    border: none;
    outline: none;
    cursor: pointer;
}

.portfolio-buttons-normal {
    background-color: #efefef;
}

.portfolio-buttons-active {
    background-color: #efefef;
    color: black;
}
