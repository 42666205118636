ion-grid {
    padding: 0;
}

/* search bar & header */
.earnSection-ionSearchBar {
    --color: var(--primary-color-green);
    --background: #fff;
    --placeholder-color: var(--primary-color-inactive-gray);
    --icon-color: var(--primary-color-inactive-gray);
    --clear-button-color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}

.earnSection__container {
    background-color: #f3f5f7;
    /* padding-top: 120px; */
}

.earnSection__searchbar--close--btn {
    color: var(--primary-color-green);
    /* margin-right: -20px; */
    margin-left: -65px;
    margin-top: -12px;
    /* margin-bottom: -5px; */
    font-size: 1.5rem;
    position: absolute;
}

.earnSection__searchbar {
    background-color: #fff;
    box-shadow: 0px 0px 8px 0px rgba(26, 26, 26, 0.25);
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}
.earnSection__searchbar--container {
    align-items: center;
    display: flex;
    /* padding: 0 30px; */
    padding-bottom: 15px;
    padding-top: 40px;
    padding-left: 10px;
    text-align: center;
}
.earnSection__searchbar--input--wrap {
    background-color: #fff;
    border: none;
    position: relative;
    width: 100%;
    z-index: 10;
}
.earnSection__searchbar--input--icon {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    top: 24px;
    left: 20px;
    position: absolute;
}
.earnSection__searchbar--input {
    color: var(--primary-color-green);
    background-color: #f6f6f6;
    border: none;
    border-radius: 8px;
    outline: none;
    padding: 12px 16px;
    padding-left: 30px;
    width: 100%;
}
.earnSection__searchbar--input::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    padding-left: 5px;
}

/* opportunities list text-title*/
/* .earnSection__title--wrap {
    align-items: right;
    display: flex;
    justify-content: right;
    margin-top: 10px;
    padding: 0 15px;
} */

/* .earnSection__title {
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
} */

/* .earnSection__btn {
    background-color: #fff;
    border-radius: 12px;
    color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    outline: none;
    justify-content: right;
} */
/* 
.earnSection__btn--icon {
    color: var(--primary-color-green);
} */

/* tasks posted items */
.earnSectionTile__row {
    align-items: center;
    background-color: #f3f5f7;
    /* border-radius: 25px; */
    display: flex;
    justify-content: center;
    width: 100%;
}
.earnSectionTile__row:last-child {
    padding-bottom: 10px;
}

.earnSectionTile__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
    padding-left: 15px;
}

.earnSectionTile {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    position: relative;
}

.earnSectionTile__info--wrap {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}
.earnSectionTile__info {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.earnSectionTile__img--wrap {
    border-radius: 15px;
    border: solid 5px #fff;
    overflow: hidden;
    position: absolute;
    width: 75px;
    height: 75px;
    left: -10px;
    top: -50px;
    z-index: 2;
    padding: 0 !important;
    background-color: #fff;
    margin-left: 5px;
    /* box-shadow: -5px 5px 15px grey; */
}
.earnSectionTile__img {
    border-radius: 0;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}
.earnSectionTile__info--name {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 0;
}
.earnSectionTile__group--wrap {
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    position: absolute;
    top: -30px;
    right: -2px;
    width: 100%;
}
.earnSectionTile__info--group {
    border-radius: 8px;
    border: solid 1px #fff;
    background: #fff;
    height: 30px;
    width: 30px;
    margin: 0;
    overflow: hidden;
    margin-left: -10px;
    padding: 0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.earnSectionTile__group--list--count {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: 0.75rem;
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 0;
}
.earnSectionTile__info--postedBy {
    align-items: center;
    display: flex;
    color: var(--primary-color-purple);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    justify-content: flex-start;
    margin: 0;
    padding: 0;
}
.earnSectionTile__info--descr {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}
