.pin {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.pin__text--wrap {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(26, 26, 26, 1);
    padding: 2px;
    position: relative;
    width: 200px;
}
.pin__text {
    font-weight: 100;
    color: black;
}
.pin__text--btn {
    position: absolute;
    top: -10px;
    right: 5px;
}

.pin__icon--wrap {
    width: 200px;
}
.pin__icon {
    height: 40px;
    width: 40px;
}
