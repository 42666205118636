.topStrokesForGreenScreen {
    top: 0;
    right: 0;
    position: absolute;
    object-fit: contain;
    z-index: 2;
}
.bottomStrokesForGreenScreen {
    bottom: 0;
    left: 0;
    position: absolute;
    transform: rotate(180deg);
    z-index: 2;
}
.topStrokesForWhiteScreen {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}
.bottomStrokesForWhiteScreen {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    bottom: 0;
    left: 0;
    transform: rotate(180deg);
    z-index: 2;
}
