.grid {
    padding: 0;
    background-color: #fff;
    width: 100%;
}
.row {
    height: 100vh;
    width: 100%;
}
.container {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.uploadBtn {
    border-radius: 15px;
    border: solid 4px var(--primary-color-off-white);
    box-shadow: 0px 0px 10px 0px rgba(26, 26, 26, 1);
    height: 100px;
    width: 100px;
    z-index: 10;
}
.uploadIcon {
    font-size: 2rem;
    color: #10b080;
}

.content {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.content h1 {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
    text-align: center;
}
.content h1 span {
    color: #10b080;
}
.text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0 10px 20px 10px;
    text-align: center;
}
.uploadText {
    color: var(--primary-color-light-gray);
    font-size: var(--xsmall-text);
    font-weight: 500;
    text-align: center;
}

.continueBtn {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    margin-top: 20px;
    width: 70%;
    z-index: 999;
}

.preview {
    background-color: #fff;
    border-radius: 15px;
    border: solid 4px var(--primary-color-off-white);
    box-shadow: 0px 0px 10px 0px rgba(26, 26, 26, 1);
    height: 100px;
    margin-bottom: 10px;
    text-align: center;
    overflow: hidden;
    width: 100px;
    padding: 0 !important;
    z-index: 10;
}

.preview img {
    background-color: #fff;
    height: 100% !important;
    width: 100% !important;
}
