.greenStrokesHeader__row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    width: 100%;
}
.greenStrokesHeader__img--wrap {
    border-radius: 15px;
    border: solid 7px #fff;
    overflow: hidden;
    position: absolute;
    width: 125px;
    height: 125px;
    left: 25px;
    top: -140px;
    z-index: 2;
    padding: 0;
    background-color: #fff;
    /* box-shadow: -5px 5px 15px grey; */
}
.greenStrokesHeader__img {
    border-radius: 0;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}

.greenStrokesHeader__btn--search {
    position: absolute;
    color: var(--primary-color-white);
    right: 8%;
    top: 65%;
}
.greenStrokesHeader__btn--search--icon {
    position: absolute;
    color: var(--primary-color-white);
    font-size: 2rem;
}

/* profile header */
.greenStrokesHeader {
    background: var(--primary-linear-gradient);
    width: 100%;
}

.greenStrokesHeader__col--top {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    padding: 80px 25px 0px 25px;
}
.greenStrokesHeader__col--img {
    margin-top: 100px;
    position: relative;
}
.greenStrokesHeader__col--bottom {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: left;
    padding-right: 25px;
}
.greenStrokesHeader__name {
    border-bottom: none;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    padding-bottom: 30px;
}
.greenStrokesHeader__btn {
    color: var(--primary-color-white);
}
.greenStrokesHeader__Home {
    height: 300px;
}
