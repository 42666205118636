ion-grid {
    padding: 0;
    min-height: 100%;
}
.ionic-content {
    /* removes scrollable effect from fullscreen view */
    --offset-bottom: 0 !important;
}

/* wrappers */
.login__container {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.login__wrapper {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

/* back btn */
.login__back--btn {
    position: absolute;
    left: 0%;
    top: 2%;
    color: var(--primary-color-black);
    z-index: 3;
}
.login__back--btn--icon {
    font-size: 2rem;
    z-index: 3;
}

/* imgs */
.login__logo {
    margin-bottom: 50px;
    height: 75px;
    width: 75px;
}

.login__top--strokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.login__bottom--strokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
    -webkit-filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
}

/* login form */
.login__form {
    background-color: transparent;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 99;
}
.login__form--wrap {
    background-color: transparent;
    margin: 0;
    padding: 0;
    text-align: center;
}
.login__form--container {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 100%;
}

.login__form--input--wrap {
    width: 95%;
}
.login__form--input {
    color: var(--primary-color-black);
    background-color: #f6f6f6;
    border: none;
    border-radius: 8px;
    outline: none;
    padding: 12px 16px;
    margin-bottom: 10px;
    width: 100%;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.login__form--input--password--wrap {
    position: relative;
}
.login__form--input--password--icon {
    color: var(--primary-color-black);
    position: absolute;
    top: 25%;
    right: 5%;
}
.login__form--input::placeholder {
    color: rgba(40, 41, 42, 0.2);
    font-size: var(--xsmall-text);
    font-weight: var(--font-semi-bold);
}

.login__form--submit {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    width: 95%;
}

.login__forgotPassword {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
}
.login__forgotPassword--btn {
    border-bottom: solid 1px var(--primary-color-black);
    color: var(--primary-color-black);
    font-size: var(--xsmall-text);
    padding: 0;
    margin: 0;
}

/* type divider */
.login__typeDivider {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    width: 100%;
}
.login__typeDivider__text {
    border-bottom: 1px solid var(--primary-color-inactive-gray);
    color: var(--primary-color-inactive-gray);
    line-height: 0.1em;
    text-align: center;
}
.login__typeDivider__text--span {
    background: #fff;
    padding: 0 10px;
}

/* auth buttons */
.login__btns {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    width: 100%;
}
.login__btn--container {
    align-items: center;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login__btn--google {
    background-color: #fff;
    border-radius: 8px;
    border: solid 1px var(--primary-color-inactive-gray);
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    width: 80%;
    z-index: 2;
}

.login__btn--google--icon {
    width: 20px;
}

/* ! error handling */
.login__error--container {
    align-items: center;
    background-color: #fff;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 100%;
}
.login__error--col {
    text-align: center;
    margin: 0;
    padding: 15px 0;
}

.login__form--input--error {
    border: solid 1px var(--primary-color-red);
}
