.reviewsFallback {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0;
    text-align: center;
    width: 100%;
}

.reviewsFallback__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular-bold);
    margin: 0;
    padding-bottom: 10px;
}

.reviewsFallback__text {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}
