.gratitudeSettingsTitle {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    font-size: 23.5px;
    padding: 0;
    margin-bottom: 3%;
}
.gratitudeSettingsTitle span {
    color: var(--primary-color-green);
}
.settingsGratitudePotTitlePurple {
    border-bottom: none;
    color: #9e2ab8;
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
    text-align: left;
    width: 100%;
    margin-top: 1%;
    margin-left: 2%;
    margin-bottom: 1%;
}
.gratitudePotHeaderImg {
    border: solid 6px white;
    background-color: white;
    border-radius: 15px;
    height: 120px;
    width: 120px;
    overflow: hidden;
    z-index: 10;
}
.gratitudeSettings__col {
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.gratitudeSettingsYINGLogoLightPurple {
    height: 180px;
    width: 180px;
}
