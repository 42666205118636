ion-slides {
    background-color: #fff;
    height: 100%;
}

.swiper-pagination-bullet {
    --bullet-background: #37469b;
    --bullet-background-active: #10b080;
}

/* content */
.getStarted__slide--container {
    align-items: center;
    display: flex;
    justify-content: center;
}

.getStarted__slide--row {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.getStarted__slide--col {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.getStarted__slide--col--mid {
    margin-top: 50px;
    margin-bottom: 50px;
}

/* images */
.getStarted__slide--img {
    height: 200px;
    width: 200px;
}

/* text */
.getStarted__slide--sub--title {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
}

.getStarted__slide--sub--title--span {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 300;
}

.getStarted__slide--title {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
}

.getStarted__slide--title--span {
    color: #10b080;
}

.getStarted__slide--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}

/* btn */
.getStarted__btn--exit {
    position: absolute;
    left: 11%;
    top: 5%;
    color: black;
}

.getStarted__btn {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    margin-bottom: 10px;
    outline: none;
    text-align: center;
    z-index: 99;
    width: 70%;
}

.prevBackButtons {
    display: flex;
    justify-content: space-between;
    align-items: right;
}

.hidePrevButton {
    visibility: hidden;
}

.showPrevButton {
    color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin-left: 38%;
}

/* styling of Prev button on desktop */
@media (max-width: 30em) {
    .showPrevButton {
        color: var(--primary-color-green);
        font-family: var(--ion-font-poppins-family);
        font-weight: var(--font-semi-bold);
        margin-left: 1%;
    }
}

.hideNextButton {
    visibility: hidden;
}

.showNextButton {
    color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin-right: 38%;
}

/* styling of Next button on desktop */
@media (max-width: 30em) {
    .showNextButton {
        color: var(--primary-color-green);
        font-family: var(--ion-font-poppins-family);
        font-weight: var(--font-semi-bold);
        margin-right: 1%;
    }
}
