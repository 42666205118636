ion-grid {
    padding: 0;
}

/* signup header */
.taskInvite__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.taskInvite__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.taskInvite__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.taskInvite__header--img {
    border: solid 6px #fff;
    border-radius: 15px;
    height: 120px;
    width: 120px;
    z-index: 10;
}
.taskInvite__back--btn {
    position: absolute;
    left: 6%;
    top: 6%;
    color: #fff;
}
.taskInvite__back--btn--icon {
    font-size: 2rem;
}
.taskInvite__close--btn {
    position: absolute;
    right: 11%;
    top: 10%;
    color: #fff;
    z-index: 99;
}

/* skillSelection text content */
.taskInvite__textContent {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
}
.taskInvite__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 15px;
    padding-top: 50px;
    text-align: center;
}
.taskInvite__textContent--sub--container {
    background-color: #fff;
    padding: 0 15px;
    text-align: center;
}
.taskInvite__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.taskInvite__textContent--title span {
    color: var(--primary-color-green);
}
.taskInvite__textContent--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
}

/* createTask btn */
.taskInvite__btn--container {
    background-color: #fff;
    padding-top: 200px;
    width: 100%;
}
.taskInvite__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    z-index: 10;
}
.taskInvite__btn {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    width: 80%;
}

/* task invite groups list */
.taskInvite__form--container {
    background-color: #fff;
    padding: 0 15px;
    padding-bottom: 80px;
    width: 100%;
}
.taskInvite__form--row {
    border-radius: 15px;
    background-color: #f3f5f7;
    padding: 10px 10px;
    width: 100%;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.taskInvite__form--row--title {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
    padding: 5px 0;
    text-align: center;
    width: 100%;
}
.taskInvite__col--wrap {
    background-color: #fff;
    border-radius: 15px;
    padding: 5px 5px;
    width: 100%;
}
.taskInvite__col {
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    /*margin-bottom: 10px;
     */
}

.taskInvite__content {
    align-items: center;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    padding: 15px 5px;
    position: relative;
    text-align: center;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.taskInvite__content--active {
    background-color: rgba(16, 176, 128, 0.205);
}

.taskInvite__content--place {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}
.taskInvite__content--place--active {
    color: var(--primary-color-green);
}
.taskInvite__content--address {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}
.taskInvite__content--address--active {
    color: var(--primary-color-green);
}

/* task invite members */
.taskInvite__members--row {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
}
.taskInvite__members--row:last-child {
    padding-bottom: 10px;
}

.taskInvite__members {
    align-items: flex-start;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    position: relative;
}

.taskInvite__members--info {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.taskInvite__members--img--wrap {
    border-radius: 10px;
    height: auto;
    padding: 0;
    margin-left: 5px;
    position: absolute;
    left: -10px;
    top: -50px;
    width: 75px;
}
.taskInvite__members--img {
    border: solid 2px #fff;
    border-radius: 10px;
    height: auto;
    overflow: hidden;
    width: 100%;
}
.taskInvite__members--info--name {
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 0;
}
.taskInvite__members--info--reviews {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: 0.75rem;
    margin: 0;
}
.taskInvite__members--skills--wrap {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
}
.taskInvite__members--skill {
    background-color: #fff;
    border-radius: 8px;
    color: var(--primary-color-black);
    font-size: x-small;
    height: 25px;
}

/* invite checkbox */
.taskInvite__members--checkbox {
    position: absolute;
    right: 2%;
    top: 5%;
    transform: scale(1.5);
}
