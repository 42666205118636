ion-grid {
    padding: 0;
}

/* signup header */
.cancelTask__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.cancelTask__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.cancelTask__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.cancelTask__header--img {
    border-radius: 15px;
    border: solid 4px #fff;
    height: 120px;
    width: 120px;
    z-index: 10;
}
.cancelTask__back--btn {
    position: absolute;
    left: 11%;
    top: 10%;
    color: #fff;
}

/* skillSelection text content */
.cancelTask__textContent {
    background-color: #fff;
}
.cancelTask__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 10px;
    padding-top: 50px;
    text-align: center;
}
.cancelTask__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.cancelTask__textContent--title span {
    color: var(--primary-color-green);
}
.cancelTask__textContent--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
}

/* skill definition form */
.cancelTask__form--container {
    background-color: #fff;
    padding: 0 15px;
    padding-bottom: 30px;
}
.cancelTask__form--row {
    border-radius: 8px;
    background-color: #f3f5f7;
    padding: 10px 10px;
    width: 100%;
}
.cancelTask__form--input--wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
/* textarea */
.cancelTask__form--textarea {
    color: var(--primary-color-black);
    border: none;
    border-radius: 8px;
    height: 150px;
    outline: none;
    padding: 12px 12px;
    resize: none;
    width: 100%;
}
.cancelTask__form--textarea::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}

/* createTask btn */
.cancelTask__btn--container {
    background-color: #fff;
    width: 100%;
}
.cancelTask__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    z-index: 10;
}
.cancelTask__btn {
    background: linear-gradient(91deg, #d25a5a 0%, #ee6464 100%);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    width: 80%;
}
