/* .notifications-toolbar {
    padding-bottom: 16px;
}
.notifications-ionSearchBar {
    --color: var(--primary-color-green);
    --background: #f6f6f6;
    --placeholder-color: var(--primary-color-inactive-gray);
    --icon-color: var(--primary-color-inactive-gray);
    --clear-button-color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}
.notifications__close--btn {
    color: var(--primary-color-green);
    margin-right: 15px;
    margin-left: 0px;
    font-size: 2rem;
} */

.notificationDetails {
    background: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -60px;
    position: relative;
    /* margin-bottom: 0px; */
    width: 100%;
}

.notificationDetails__col--btn {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -25px;
}

.notification__title--wrap {
    justify-content: space-between;
    padding-right: 15px;
}

.notifications--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 20px 20px;
    padding-top: 45px;
}

/* notification item slide */
.notificationTile__slide--row {
    background-color: #f3f5f7;
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    /* padding-right: 15px; */
    width: 100%;
}
.notificationTile__slide--item {
    background-color: #f3f5f7;
    padding: 0;
    margin: 0;
}
.notificationTile__slide--options {
    background-color: #f3f5f7;
    padding: 0;
    margin: 0;
}
.notificationTile__slide--option {
    background: linear-gradient(106deg, #ff4242, #ff8c8c);
    border-radius: 12px;
    padding: 0;
    margin: 0;
}

/* requests hotchips */
.notificationTile__row {
    padding-right: 12px;
    width: 100%;
}
.notificationTile__row:last-child {
    margin-bottom: 40px;
}

.notificationTile__col-unread {
    align-items: flex-start;
    background: rgb(68, 201, 131);
    background: linear-gradient(
        200deg,
        rgb(155, 102, 255) 0%,
        rgb(96, 54, 255) 100%
    );

    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.notificationTile__col-read {
    align-items: flex-start;
    background: #f3f5f7;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.notificationTile__avatar--img {
    border: solid 4px #fff;
    border-radius: 12px;
    height: 45px;
    width: 45px;
}
.notificationTile__content {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-align: left;
}
.notificationTile__content--badge--purple {
    background: linear-gradient(135deg, #6236ff, #987df7);
    border-radius: 100px;
    height: 70px;
    padding-left: 0px;
    padding-top: 22px;
    position: absolute;
    right: -25px;
    top: -25px;
    width: 70px;
}
.notificationTile__content--badge--red {
    background: linear-gradient(106deg, #ff4242, #ff8c8c);
    border-radius: 100px;
    height: 70px;
    padding-left: 0px;
    padding-top: 22px;
    position: absolute;
    right: -25px;
    top: -25px;
    width: 70px;
}
.notificationTile__content--badge--yellow {
    background: var(--primary-color-yellow);
    border-radius: 100px;
    height: 70px;
    padding-left: 0px;
    padding-top: 22px;
    position: absolute;
    right: -25px;
    top: -25px;
    width: 70px;
}
.notificationTile__content--badge--text {
    color: var(--primary-color-white);
    font-size: var(--xsmall-text);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    text-align: center;
    margin: 0;
    padding: 0;
    padding-right: 10px;
}

.notificationTile__content--title {
    color: black;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}

.notificationTile__content--skill {
    color: var(--primary-color-purple);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
}
.notificationTile__content--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}

/* action card */
.notificationTile__actionCard {
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    margin: 0;
    width: 90%;
}

.notificationTile__actionCard--title {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 0;
}
.notificationTile__actionCard--text {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    text-align: center;
}

.notificationTile__actionCard--backdrop {
    background-color: red;
}

.notificationTile__actionCard--btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
}
.notificationTile__actionCard--btn--update {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    width: 50%;
}
.notificationTile__actionCard--btn--cancel {
    background: linear-gradient(106deg, #ff4242, #ff8c8c);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    width: 50%;
}
