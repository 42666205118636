ion-grid {
    padding: 0;
}
.liContainer {
    margin-left: 100px;
}

.YING__title {
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-bold);
    font-size: 2rem;
    color: var(--primary-color-black);
    text-align: center;
}
.Terms__title {
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-bold);
    font-size: 1.5rem;
    color: var(--primary-color-black);
    text-align: center;
}
.Update__title {
    font-family: var(--ion-font-poppins-family);
    font-size: 1.5rem;
    color: var(--primary-color-black);
    text-align: center;
}

/* strokes */
.about__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 32px 32px;
    position: absolute;
    top: 0;
    left: 0;
}
.about__btn {
    color: var(--primary-color-black);
}
.about__top--strokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.about__bottom--strokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
    -webkit-filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
}

/* manifesto title */
.about__title--container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    width: 100%;
}
.about__title--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
}
.about__title {
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-bold);
    font-size: 2rem;
    color: var(--primary-color-black);
}
.about__title span {
    color: var(--primary-color-green);
}

.about__values--container {
    margin-top: 0;
}
/* manifesto content */
.about__manifesto--container {
    background-color: #fff;
    width: 100%;
    padding: 1px;
}

.about__manifesto--wrap {
    border-radius: 15px;
    border: solid 10px #f6f6f6;
}

.about__manifesto--content {
    background-color: #fff;
    border-radius: 15px;
    padding: 12px 16px;
    text-align: left;
    z-index: 10;
}

.about__manifesto--contactUs {
    background-color: #fff;
    border-radius: 15px;
    padding: 12px 16px;
    text-align: center;
    z-index: 10;
}

.about__manifesto--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-regular);
    font-size: var(--small-text);
}
.about__manifesto--text span {
    color: var(--primary-color-purple);
}

/* core values content */
.about__core--container {
    background-color: #fff;
    width: 100%;
    padding: 0 15px;
    padding-bottom: 50px;
}

.about__core--wrap {
    background-color: #f6f6f6;
    border-radius: 15px;
    border: solid 5px #f6f6f6;
}

.about__core--content {
    background-color: #fff;
    border-radius: 15px;
    border: solid 5px #f6f6f6;
    padding: 12px 16px;
    text-align: left;
    z-index: 10;
}

.about__core--title {
    color: var(--primary-color-purple);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: 1.2rem;
    margin: 0;
    margin-bottom: 10px;
}

.about__core--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-regular);
    font-size: var(--small-text);
    padding: 0;
    margin: 0;
}

/*legal screens CSS*/
.buttermilk {
    background-color: #fee9ae;
}
.poloBlue {
    color: #9ab0cb;
}
.tableMargin {
    margin-top: 1%;
    color: black;
}
.regular {
    color: black;
}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    vertical-align: top;
    padding: 8px;
}
.backX {
    color: black;
    font-size: 35px;
    margin-left: 5%;
    margin-bottom: 0%;
}
.endOfTerms {
    text-align: center;
}
.legalBackBtn {
    background-color: transparent;
    font-size: 30px;
}
.CCPAGrid {
    width: 134%;
    margin-left: -54px;
}
.CCPACol {
    min-width: 110px;
    max-width: 115px;
    text-align: center;
    border-radius: 0px;
    border: 0.5px solid #dddddd;
}
.thirdColumn {
    text-align: center;
    min-width: 75px;
    max-width: 75px;
    border-radius: 0px;
    border: 0.5px solid #dddddd;
}
.CCPARow {
    width: 86%;
}
.CCPAWrapper {
    max-width: 500px;
    width: 115%;
    margin-left: -30px;
}
.CCPACol {
    width: 50%;
}
.CCPATable {
    table-layout: fixed;
    width: 100%;
}
