ion-grid {
    padding: 0;
}

/* signup header */
.skillDef__header {
    background-color: red;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.skillDef__header--container {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 30px;
    position: relative;
}

.skillDef__header--strokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}

/* skill badge */
.skillDef__badge--container {
    align-items: center;
    display: flex;
    background-color: #fff;
    justify-content: center;
    width: 100%;
}
.skillDef__badge {
    border-radius: 10px;
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    height: 50px;
    padding: 0;
    text-align: center;
    z-index: 10;
}

/* skill definition form */
.defSkill__form--container {
    background-color: #fff;
    padding: 0 15px;
    padding-bottom: 30px;
}
.defSkill__form--row {
    border-radius: 12px;
    background-color: #f3f5f7; /* zeplin container color */
    padding: 10px 10px;
    width: 100%;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.defSkill__form--input--wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
.defSkill__form--label {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: 0.75rem;
    font-weight: var(--font-regular);
    margin-bottom: 5px;
    margin-left: 5px;
}
.defSkill__form--input {
    color: var(--primary-color-black);
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    outline: none;
    resize: none;
    width: 100%;
}
.defSkill__form--input::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}

/* form btns */
.defSkill__form--btns--container {
    align-items: center;
    display: flex;
    flex-direction: row;
}
.defSkill__form--btn {
    background-color: #fff;
    border: none;
    border-radius: 8px;
    color: var(--primary-color-green);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    width: 100%;
}

/* skillDef confirm btn */
.skillDef__btn--container {
    background-color: #fff;
    width: 100%;
}
.skillDef__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    z-index: 10;
}
.skillDef__btn {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    color: #fff;
    width: 80%;
}

/* copyright */
.skillDef__footer {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
}
.skillDef__footer--strokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
}
