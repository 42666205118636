/* google places auto complete drop down */
.autocomplete__dropdown--container {
    background-color: #fff;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 0px 3px 24px -4px rgba(40, 41, 42, 0.2);
    border: none;
    padding: 0;
}

.autocomplete__dropdown--item {
    border-bottom: solid 1px rgb(40, 41, 42, 0.1);
    padding: 6px 16px;
}

.autocomplete__dropdown--container .autocomplete__dropdown--item:last-child {
    padding-bottom: 24px;
    border-bottom: none;
}

.autocomplete__dropdown--item--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
    margin: 0;
}
