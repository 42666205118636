/* providerItem */
.tasks__row {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}
.tasks__row:last-child {
    padding-bottom: 10px;
}

.providerItem {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    position: relative;
}

.providerItem__info {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.providerItem__img--wrap {
    border-radius: 10px;
    height: auto;
    padding: 0;
    margin-left: 5px;
    position: absolute;
    left: -10px;
    top: -50px;
    width: 75px;
}
.providerItem__img {
    border: solid 2px #fff;
    border-radius: 10px;
    height: auto;
    overflow: hidden;
    width: 100%;
}
.providerItem__info--name {
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 0;
}
.providerItem__info--time {
    color: var(--primary-color-dark-green);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}
.providerItem__info--time span {
    color: var(--primary-color-light-white);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}
.providerItem__info--text {
    color: var(--primary-color-light-white);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}
.providerItem__btn--wrap {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.providerItem__btn--send,
.providerItem__btn--cancel {
    border-radius: 8px;
    width: 50%;
}
.providerItem__btn--inProgress {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    color: #fff;
    width: 100%;
}
.providerItem__btn--cancel {
    background-image: linear-gradient(106deg, #ce5959, #f56666);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    color: #fff;
}
.providerItem__btn--send {
    background: linear-gradient(106deg, #0091ff, #32c5ff);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    color: #fff;
}

/* info icon */
.providerItem__info--icon {
    color: var(--primary-color-green);
    font-size: 1.6rem;
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: 10;
}
