.btn {
    margin: 16px;
    margin-top: 40px;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: transparent;
}

.icon {
    /* color: black; */
    font-size: 2.4rem;
}
