/* ReactCrop component overrides */
.ReactCrop {
    background-color: #f3f5f7 !important;
    max-width: 320px !important;
}

.ReactCrop__image {
    background-color: #f3f5f7 !important;
    display: block;
    max-width: 100%;
    touch-action: manipulation;
}
