.payment_methods_container {
    background-color: #fff;
    padding: 0 15px;
}

.no_payment_methods_heading {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.payment_method_card {
    margin: 5px 0px 5px 0px;
    padding: 5px;
}

.payment_methods_sub_container {
    border-radius: 15px;
    padding: 0px 5px;
    width: 100%;
}

.payment_body_container {
    width: 100%;
    padding: 0px 10px;
    align-items: center;
    justify-content: center;
}

.paymentInfo__content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.delete_icon {
    color: red;
    font-size: 1.5rem;
    align-items: flex-end;
    justify-content: flex-end;
}
