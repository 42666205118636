ion-grid {
    padding: 0;
}

/* signup header */
.serviceRequest__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.serviceRequest__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.serviceRequest__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.serviceRequest__header--img {
    border-radius: 15px;
    border: solid 6px #fff;
    height: 120px;
    width: 120px;
    overflow: hidden;
    z-index: 10;
}
.serviceRequest__back--btn {
    position: absolute;
    left: 5%;
    top: 8%;
    color: #fff;
}
.serviceRequest__back--btn--icon {
    font-size: 2rem;
}

/* skillSelection text content */
.serviceRequest__textContent {
    background-color: #fff;
    margin-bottom: 30px;
}
.serviceRequest__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 15px;
    padding-top: 50px;
    position: relative;
    text-align: center;
}
.serviceRequest__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.serviceRequest__textContent--title span {
    color: var(--primary-color-green);
}

.serviceRequest__text--icon {
    color: var(--primary-color-green);
    font-size: 2rem;
    position: absolute;
    right: 0;
    top: 0;
}

.serviceRequest__textContent--text--scheduled {
    color: var(--primary-color-purple);
    font-family: var(--ion-font-poppins-family);
    font-weight: 600;
    padding: 0;
    margin: 0;
}

.serviceRequest__textContent--text--denied {
    color: #ff4242;
    font-family: var(--ion-font-poppins-family);
    font-weight: 600;
    padding: 0;
    margin: 0;
}

.serviceRequest__textContent--text--requested {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 600;
    padding: 0;
    margin: 0;
}

/* skill definition form */
.serviceRequest__request--container {
    background-color: #fff;
    padding: 0 15px;
    padding-bottom: 80px;
}
.serviceRequest__request--row {
    border-radius: 15px;
    background-color: #f3f5f7;
    padding: 10px 10px;
    padding-top: 30px;
    position: relative;
    width: 100%;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.serviceRequest__request--wrap {
    background-color: #fff;
    border-radius: 15px;
    padding: 5px 10px;
}

/* serviceRequest details */
.serviceRequest__details--wrap {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
}
.serviceRequest__details--img--wrap {
    border-radius: 15px;
    border: none;
    border: solid 4px #fff;
    height: 75px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -40px;
    width: 75px;
}
.serviceRequest__details--img {
    border-radius: 15px;
    height: 100%;
    overflow: hidden;
    width: 100%;
}
.serviceRequest__text--wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5px;
}
.serviceRequest__details--name {
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 0;
}
.serviceRequest__details--time {
    color: var(--primary-color-purple);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}

/* msg */
.serviceRequest__msg--wrap {
    padding: 0;
    margin: 0;
    text-align: left;
}
.serviceRequest__msg {
    color: var(--primary-color-light-white);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
    padding-left: 10px;
    padding-top: 10px;
}

/* btn wrap  */
.serviceRequest__btn--wrap {
    display: flex;
}
.serviceRequest__btn--accept {
    background-image: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    width: 33%;
}
.serviceRequest__btn--send {
    background: linear-gradient(106deg, #0091ff, #32c5ff);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    width: 33%;
}
.serviceRequest__btn--decline {
    background: #fff;
    border: solid 2px var(--primary-color-green) !important;
    border-radius: 8px;
    color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    width: 33%;
}

/* request service */
.serviceRequest__footer--wrap {
    background-color: #f3f5f7;
    text-align: center;
    padding: 10px 10px;
    margin: 0;
}
.serviceRequest__footer--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-regular);
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
}
