.groupProfileHeader__row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    width: 100%;
}
.groupProfileHeader__img--wrap {
    border-radius: 15px;
    border: solid 7px #fff;
    overflow: hidden;
    position: absolute;
    width: 125px;
    height: 125px;
    left: 25px;
    top: -100px;
    z-index: 2;
    padding: 0 !important;
    background-color: #fff;
    /* box-shadow: -5px 5px 15px grey; */
}
.groupProfileHeader__img {
    border-radius: 0;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}

/* profile header */
.groupProfileHeader {
    background: var(--primary-linear-gradient);
    width: 100%;
}

.groupProfileHeader__col--top {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    padding: 60px 25px 0px 25px;
    position: relative;
}
.groupProfileHeader__col--img {
    margin-top: 100px;
    position: relative;
}
.groupProfileHeader__img--strokes {
    top: 0;
    position: absolute;
    right: 0;
    object-fit: contain;
}

.groupProfileHeader__col--bottom--left {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: left;
    padding-right: 25px;
}
.groupProfileHeader__col--bottom--right {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
    padding-right: 25px;
    padding-left: 50px;
}
.groupProfileHeader__name {
    border-bottom: none;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    padding-bottom: 30px;
}
.groupProfileHeader__btn {
    color: var(--primary-color-white);
    font-size: 1.2rem;
}
.groupProfileHeader__btn--search {
    position: absolute;
    color: var(--primary-color-white);
    right: 8%;
    bottom: -10%;
}
.groupProfileHeader__btn--search--icon {
    position: absolute;
    color: var(--primary-color-white);
    font-size: 2rem;
}
