.container {
    background-color: #f3f5f7;
    margin: 0px 15px;
    border-radius: 10px;
    font-family: var(--ion-font-poppins-family);
    margin-top: 20px;
    padding: 0px;
}
.user-img {
    border-radius: 12px;
    border: solid 4px #fff;
    height: 45px;
    width: 45px;
    position: absolute;
    left: 0%;
    margin-left: 10px;
}
/* .img-bg{
  background-color: white;
  border-radius: 40%;
  padding: 5px;
  width: 75%;
  margin: auto;
} */
.detail-container {
    padding: 10px;
    padding-bottom: 5px;
    padding-top: 7px;
    background-color: white;
    border-radius: 10px;
}
.detail-name {
    color: black;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    padding-bottom: 5px;
    margin: 0;
}
.detail-time {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    padding-bottom: 3px;
    margin: 0;
}
.detail-amount {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}
.recipient-color {
    color: red;
}
.provider-color {
    color: green;
}
