/* chatroom header */
.chatRoom__header {
    align-items: center;
    background: var(--primary-linear-gradient);
    display: flex;
    flex-direction: row;
    height: 15vh;
    justify-content: flex-start;
    text-align: left;
}
.chatRoom__header--img {
    object-fit: contain;
    position: absolute;
    right: 0;
    top: 0;
}

/* chatroom header details */
.chatRoom__header--details--col {
    align-items: flex-start;
    background: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    height: 5vh;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: -30px;
    padding: 0 25px;
    position: relative;
    width: 100%;
}
.chatRoom__details--img--wrap {
    display: flex;
    border-radius: 15px;
    border: solid 3px #fff;
    height: 65px;
    overflow: hidden;
    margin-top: -45px;
    padding: 0;
    width: 65px;
}
.chatRoom__details--img {
    height: auto;
    margin: 0;
    padding: 0;
    width: 100%;
}
.chatRoom__details--name {
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: 1.3rem;
    font-weight: var(--font-semi-bold);
    margin-top: -35px;
    margin-left: 10px;
}
.chatRoom__details--icon {
    position: absolute;
    right: 15px;
    top: -40px;
    color: #fff;
    font-size: 2rem;
    z-index: 5;
}

/* chat convo */
.chatRoom__convo--container {
    height: 83vh;
    overflow-y: scroll;
    width: 100%;
}
.chatRoom__convo {
    padding: 0 15px;
}
.chatRoom__convo:first-child {
    padding-top: 19px;
}

/* input container */
.chatRoom__input--form {
    align-items: center;
    border-top: solid 1px #f6f6f6;
    background-color: #fff;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: relative;
    padding: 12px 16px;
    width: 100%;
}
.chatRoom__input {
    background-color: #f6f6f6;
    border: none;
    border-radius: 50px;
    outline: none;
    padding: 22px 56px 22px 32px;
    resize: none;
    width: 100%;
}
.chatRoom__submit {
    background: linear-gradient(106deg, #0091ff, #32c5ff);
    border: none;
    border-radius: 100px;
    height: 50px;
    margin: 0;
    margin-left: -55px;
    width: 50px;
    z-index: 999;
}

.chatRoom__submit--icon {
    color: #fff;
    padding: 0;
    margin: 0;
}

/* chat bubbles */
.chat__sender--col {
    align-items: flex-end;
    background-color: #fff;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    padding-left: 100px;
    position: relative;
    text-align: right;
    width: auto;
}
.chat__sender--icon--wrap {
    background: linear-gradient(106deg, #ff4242, #ff8c8c);
    border-radius: 100px;
    position: absolute;
    top: -17px;
    right: -10px;
    padding: 5px 8px;
    z-index: 10;
}
.chat__sender--icon {
    color: #fff;
    font-size: 1.1rem;
}
.chat__sender--text {
    border-radius: 25px;
    border-bottom-right-radius: 0;
    background: linear-gradient(106deg, #0091ff, #32c5ff);
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: 400;
    padding: 12px 10px 12px 12px;
    margin: 0;
    white-space: pre-wrap;
}

.chat__receiver--col {
    align-items: flex-start;
    background-color: #fff;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    padding-right: 100px;
    text-align: left;
    width: auto;
}

.chat__receiver--text {
    border-radius: 25px;
    border-bottom-left-radius: 0;
    background-color: #f6f6f6;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: 400;
    padding: 12px 12px 12px 16px;
    margin: 0;
    white-space: pre-wrap;
}

.chat__timestamp {
    color: var(--primary-color-light-white);
    font-size: x-small;
    padding: 0;
    margin: 0;
}

.messageScroll {
    overflow-x: hidden;
    overflow-y: visible;
}
