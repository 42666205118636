/* user menu */
.menu__btn--open {
    position: absolute;
    left: 2%;
    top: 5%;
    z-index: 100;
}
.menu__btn--open--icon {
    font-size: 2rem;
}
.menu__btn--close {
    z-index: 100;
}
.menu__btn--close--icon {
    font-size: 2rem;
}
.menu__btn--logout {
    border-radius: 8px;
    background: var(--primary-linear-gradient-v2);
    width: 80%;
}
.menu__sidebar--active {
    background-color: #ffffff;
    box-shadow: 5px 0 6.5px 3px rgba(0, 0, 0, 0.1);
    height: 100%;
    /* overflow-x: hidden; */
    overflow-y: scroll;
    position: fixed;
    width: 70%;
    transition: all 0.3s ease-out;
    padding: 0;
    margin: 0;
    left: 0px;
    z-index: 9999;
    position: absolute;
}
.menu__sidebar--inactive {
    background-color: #fff;
    height: 100%;
    padding: 0;
    margin: 0;
    position: fixed;
    /* overflow-x: hidden; */
    overflow-y: scroll;
    /* width: 0; */
    left: -100%;
    transition: all 0.3s ease-out;
    z-index: 99;
}
.menu__header {
    align-items: right;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 15px;
}
.menu__sidebar--list {
    background-color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;
    flex-direction: column;
    padding: 0 12px;
    padding-bottom: 40px;
}
.menu__sidebar--item {
    background-color: #f3f5f7;
    border: none;
    border-radius: 12px;
    color: #28292a;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    font-size: 0.85rem;
    letter-spacing: -0.15px;
    margin-bottom: 10px;
    height: 45px;
    width: 100%;
    outline: none;
}
.menu__sidebar--item--img {
    border-radius: 8px;
    border: solid 1px #fff;
    height: 35px;
    overflow: hidden;
    width: 35px;
    background-color: #fff;
}
.menu__sidebar--item--outlineOnly {
    background-color: #fff;
    border: solid 3px #f3f5f7;
}
.menu__sidebar--item--cashout {
    background: var(--primary-linear-gradient-v2);
    border: none;
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    font-size: 0.75rem;
    margin-bottom: 10px;
    height: 45px;
    width: 100%;
}
.menu__sidebar--item .menu__sidebar--item--span {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}
.menu__sidebar--item--icon {
    font-size: 1.3rem;
    color: var(--primary-color-green);
    padding-right: 10px;
}
.menu__sidebar--item--icon--msg {
    font-size: 1.3rem;
    color: #0091ff;
    padding-right: 10px;
}
.menu__sidebar--item--icon--dash {
    font-size: 1.3rem;
    color: var(--primary-color-light-white);
    padding-right: 10px;
}
.menu__sidebar--item--icon--check {
    font-size: 1.3rem;
    color: var(--primary-color-purple);
    padding-right: 10px;
}
.menu__sidebar--item--icon--timer {
    font-size: 1.3rem;
    color: var(--primary-color-green);
    padding-right: 10px;
}
.menu__sidebar--item--icon--brief {
    font-size: 1.3rem;
    color: var(--primary-color-green);
    padding-right: 10px;
}
.menu__sidebar--item--icon--list {
    font-size: 1.3rem;
    color: var(--primary-color-purple);
    padding-right: 10px;
}

/* sidebar footer */
.menu__sidebar--footer {
    align-items: center;
    display: flex;
    background-color: #f3f5f7;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    padding-top: 10px;
    margin-top: -20px;
    width: 100%;
}
.menu__sidebar--footer--item {
    background-color: #fff;
    border: none;
    border-radius: 12px;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    font-size: 0.85rem;
    letter-spacing: -0.15px;
    margin-bottom: 10px;
    height: 45px;
    width: 88%;
}
.menu__sidebar--footer--item .menu__sidebar--footer--item--span {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

/* group item */
.menu__sidebar--groupItem {
    align-items: center;
    background-color: #fff;
    border: none;
    border-radius: 12px;
    color: var(--primary-color-black);
    display: flex;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    font-size: 0.85rem;
    letter-spacing: -0.15px;
    margin-bottom: 10px;
    height: 45px;
    width: 88%;
}
.menu__groupItem--img--wrap {
    background-color: #f3f5f7;
    border-radius: 8px;
    height: 40px;
    overflow: hidden;
    width: 40px;
}

.menu__groupItem--img {
    background-color: #f3f5f7;
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.menu__groupItem--text {
    padding-left: 10px;
}

/* currency display */
.currencyDisplay__container {
    padding: 0;
    margin: 0;
}
.currencyDisplay__img {
    margin-right: 3px;
    height: 30px;
    width: 30px;
}
.currencyDisplay__img-large {
    margin-right: 3px;
    height: 40px;
    width: 40px;
}
.currencyDisplay__col--mid {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    padding: 0 15px;
}
.currencyDisplay__col--bottom {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding: 0 10px;
}

.currencyDisplay__credits--token {
    color: #10b080;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--large-text);
    font-weight: var(--font-semi-bold);
}
.currencyDisplay__credits--cash {
    color: #10b080;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    text-align: center;
    font-size: var(--medium-text);
}
.currencyDisplay__cash--description {
    color: black;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    width: 100%;
    text-align: center;
}

.currencyDisplay__credits--token::after {
    content: 'BLCN';
    color: #10b080;
    font-size: 1.4rem;
    padding: 0 0.2rem;
}

.currencyDisplay__currency--btn {
    align-items: center;
    background: none;
    border: none;
    border-bottom: solid 2px var(--primary-color-inactive-gray);
    color: #10b080;
    display: flex;
    font-family: var(--ion-font-poppins-family);
    font-size: 0.75rem;
    font-weight: var(--font-semi-bold);
    justify-content: center;
    margin: 0;
    text-align: center;
    height: 40px;
    width: 50%;
}

.currencyDisplay__currency--btn--active {
    border-bottom: solid 2px var(--primary-color-black);
    color: #10b080;
}

/* menu backdrop */
.menu__sidebar--backdrop {
    background-color: rgba(40, 41, 42, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
}

details {
    width: 100%;
    margin-top: 4px;
}

details > summary {
    background-color: #f3f5f7;
    border: none;
    border-radius: 12px;
    color: #28292a;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    font-size: 0.85rem;
    letter-spacing: -0.15px;
    margin-bottom: 10px;
    height: 45px;
    display: flex;
    align-items: center;
    list-style: none;
    outline: none;
}

/* REMOVE DEFAULT ARROW */
details > summary {
    list-style: none;
}
details > summary::-webkit-details-marker {
    display: none;
}

/* ANIMATION WHILE OPENING My Groups */
details[open] summary ~ * {
    animation: open 0.5s ease-in-out;
}
@keyframes open {
    0% {
        opacity: 0;
        margin-left: -25px;
    }
    100% {
        opacity: 1;
        margin-left: 0px;
    }
}

.transition--up {
    transform: rotate(0deg);
    transition: transform 0.3s;
}

.transition--down {
    transform: rotate(90deg);
    transition: transform 0.3s;
}
