/* header */
.communityOnboard__header {
    align-items: center;
    border-bottom: solid 1px rgb(212, 212, 212);
    display: flex;
    padding-top: 50px;
    padding-bottom: 25px;
    justify-content: center;
    width: 100%;
}
​ .communityOnboard__header--logo--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
}
​ .communityOnboard__header--logo {
    object-fit: contain;
    height: 50px;
    width: 48px;
}

/* back btn */
.communityOnboard__btn--wrap {
    align-items: center;
    background: none;
    border: none;
    display: flex;
    justify-content: flex-start;
    padding: 24px 16px;
    width: 100%;
    z-index: 10;
}
.communityOnboard__back--btn {
    color: var(--primary-color-black);
}
.communityOnboard__back--btn--icon {
    color: black;
    font-size: 2rem;
}

/* community form access code title */
.communityOnboard__form--container {
    padding-bottom: 50px;
    width: 100%;
}

.communityOnboard__form--accessCode--title--row {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}
.communityOnboard__form--accessCode--title--col {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    text-align: left;
}
.communityOnboard__form--accessCode--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    margin: 0;
    padding: 0;
}

/* community onboard input fields */
.communityOnboard__form--input--row {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.communityOnboard__form--input--col {
    align-items: center;
    display: flex;
    justify-content: center;
}

.communityOnboard__form--input {
    background-color: #f6f6f6; /* zeplin input bg-color */
    border: none;
    border-radius: 8px;
    color: var(--primary-color-black);
    outline: none;
    padding: 16px 16px;
    width: 100%;
}
.communityOnboard__form--input::placeholder {
    color: rgba(40, 41, 42, 0.2); /* zeplin input placeholder-color */
    font-size: var(--xsmall-text);
    font-weight: var(--font-semi-bold);
}

/* community onboard labels/titles */
.communityOnboard__form--label--row {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}
/* community onboard labels/titles */
.communityOnboard__form--label--row--final {
    margin-bottom: -10px;
}

.communityOnboard__form--label--col {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.communityOnboard__form--label {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
}

.communityOnboard__form--label span {
    color: var(--primary-color-green);
}

/* form submit */
.communityOnboard__form--submit {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin-top: 10px;
    outline: none;
    text-align: center;
    z-index: 99;
    width: 100%;
}

/* error handling */
.communityOnboard__form--input--error {
    border: solid 1px var(--primary-color-red);
}
.communityOnboard__form--error--wrap {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 10px 8px;
    margin: 0;
    text-align: left;
}
.communityOnboard__form--error {
    align-items: center;
    color: var(--primary-color-red);
    font-size: var(--xsmall-text);
    font-weight: 600;
    padding: 0;
    margin: 0;
    padding: 0;
    margin: 0;
}

/* spinner */
.communityOnboard__spinner--wrap {
    align-items: center;
    background-color: #fff;
    border: none;
    display: flex;
    justify-content: center;
    padding-top: 100px;
    width: 100%;
}

.communityOnboard__spinner {
    color: var(--primary-color-green);
    margin: 0;
    font-size: 10rem;
    height: 100px;
    width: 100px;
}
