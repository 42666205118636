/* complete task footer btn */
ion-footer {
    border: none;
}

ion-toolbar {
    border: none;
}

.footerWithChild__grid {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.footerWithChild__row {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 80%;
}

.footerWithChild__col {
    align-items: center;
    display: flex;
    justify-content: center;
}

.footerWithChild__btn {
    color: #fff;
    background: var(--primary-linear-gradient-v2);
    border-radius: 12px;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    width: 100%;
}
