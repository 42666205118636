ion-grid {
    padding: 0;
}

/* page containers */
.transactionWall__grid {
    height: 95vh;
}
.transactionWall {
    background: var(--primary-linear-gradient-v2);
    height: 100%;
    width: 100%;
}
.transactionWall__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0 30px;
    text-align: center;
}

.transactionWall__strokes--top {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.transactionWall__strokes--bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
}

.transactionWall__title {
    border-bottom: none;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    z-index: 2;
}
.transactionWall__text {
    border-bottom: none;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
    margin: 0;
    z-index: 2;
}
