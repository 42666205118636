/* plain html/jsx loading screen */
.yingLoader {
    align-items: center;
    display: flex;
    background: var(--primary-linear-gradient-v2);
    justify-content: center;
    height: 100vh;
    width: 100%;
}
.yingLoader__logo {
    height: 150px;
    width: 150px;
    animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    -moz-animation: bounce 2s infinite;
    -o-animation: bounce 2s infinite;
}

/* ionic based loading screen */
.loadingScreen {
    align-items: center;
    display: flex;
    background: var(--primary-linear-gradient-v2);
    height: 100%;
    justify-content: center;
    width: 100%;
}
.loadingScreen__col {
    align-items: center;
    display: flex;
    justify-content: center;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}
