.profileHeader__row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    width: 100%;
}
.profileHeader__img--wrap {
    border-radius: 15px;
    border: solid 7px #fff;
    height: 125px;
    overflow: hidden;
    position: absolute;
    width: 125px;
    left: 25px;
    top: -90px;
    padding: 0;
    z-index: 2;
}
.profileHeader__img {
    height: auto;
    margin: 0;
    padding: 0;
    width: 100%;
}

/* profile header */
.profileHeader {
    background: var(--primary-linear-gradient);
    width: 100%;
}

.profileHeader__col--top {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    padding: 60px 25px 0px 25px;
}
.profileHeader__col--img {
    margin-top: 100px;
    position: relative;
}
.profileHeader__img--strokes {
    top: 0;
    position: absolute;
    right: 0;
    object-fit: contain;
}

.profileHeader__col--bottom {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: left;
    padding-right: 25px;
}
.profileHeader__name {
    border-bottom: none;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    padding-bottom: 30px;
}
.profileHeader__btn {
    color: var(--primary-color-white);
}
