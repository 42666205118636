/* profile details */
.profileDetails {
    background: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -20px;
    position: relative;
    width: 100%;
}

.profileDetails__col--btn {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -25px;
}

.profileDetails__btn {
    background: var(--primary-color-white);
    border: solid 2px var(--primary-color-inactive-gray);
    border-radius: 8px;
    height: 45px;
    color: var(--primary-color-dark-green);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    margin: 0 8px;
    width: 170px;
}
.profileDetails__btn--icon {
    font-size: 1rem;
}

/* profileDetails bio section */
.profileDetails__row--bio {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    width: 100%;
}
.profileDetails__col--bio {
    text-align: left;
    width: 100%;
}
.profileDetails__bio {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
    white-space: pre-wrap;
}

/* portfolio */
.profileDetails__edit--col--btn {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -25px;
}

.profileDetails__edit--btn {
    background-color: #fff;
    border: solid 2px #f3f5f7;
    border-radius: 8px;
    height: 45px;
    color: var(--primary-color-dark-green);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    margin: 0 8px;
    width: 170px;
}
