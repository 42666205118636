ion-grid {
    padding: 0;
}

/* requestTask header */
.reqTaskAvl__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.reqTaskAvl__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.reqTaskAvl__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.reqTaskAvl__header--img {
    border: solid 6px #fff;
    border-radius: 15px;
    height: 120px;
    overflow: hidden;
    width: 120px;
    z-index: 10;
}
/* skill definition form */
.reqTaskAvl__form--container {
    background-color: #fff;
    padding: 0 15px;
    padding-bottom: 30px;
}
.reqTaskAvl__form--row {
    align-items: center;
    border-radius: 15px;
    background-color: #f3f5f7;
    display: flex;
    padding: 10px 10px;
    justify-content: center;
    text-align: center;
    width: 100%;
}
.reqTaskAvl__form--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
    padding: 10px 24px;
}
.reqTaskAvl__form--text span {
    color: var(--primary-color-green);
    border-bottom: solid 1px var(--primary-color-green);
}
.reqTaskAvl__col {
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 10px;
    padding: 0;
}
.reqTaskAvl__content {
    align-items: center;
    background-color: #fff;
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    margin: 0;
    padding: 5px 0;
    text-align: left;
    width: 100%;
}
.reqTaskAvl__content--day {
    align-items: center;
    background-color: #f3f5f7;
    border-radius: 10px;
    color: var(--primary-color-black);
    display: flex;
    flex-direction: column;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-bold);
    height: 60px;
    justify-content: center;
    text-align: center;
    margin: 4px;
    width: 22.13%;
}
.reqTaskAvl__content--day--text {
    padding: 0;
    margin: 0;
}
.reqTaskAvl__content--day--text span {
    font-weight: var(--font-regular);
}
.reqTaskAvl__content--day--active {
    background-color: rgba(16, 176, 128, 0.205);
    color: var(--primary-color-green);
}

/* requestTask text content */
.reqTaskAvl__textContent {
    background-color: #fff;
}
.reqTaskAvl__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 10px;
    padding-top: 50px;
    text-align: center;
}
.reqTaskAvl__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.reqTaskAvl__textContent--title span {
    color: var(--primary-color-green);
}
.reqTaskAvl__textContent--text {
    color: var(--primary-color-light-white);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
}

/* requestTask btn */
.reqTaskAvl__btn--container {
    background-color: #fff;
    width: 100%;
}
.reqTaskAvl__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 30px;
    z-index: 10;
}
.reqTaskAvl__btn {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    color: #fff;
    width: 80%;
}

.calendar__row {
    margin-top: 5px;
    border-radius: 0px;
    background-color: #f3f5f7;
    padding: 10px; /* change the width of the grey box */
    padding-top: 0px;
    width: 100%;
    -webkit-border-radius: 0px; /* change the outer edge of the calendar, the corners round a little without this */
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

/* tbd button */
.startDate__form--row-tbd {
    margin-top: -10px;
    border-radius: 0px;
    padding-top: 0px;
    background-color: #f3f5f7;
    padding: 5px; /* change the width of the grey box */
    width: 100%;
    -webkit-border-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
