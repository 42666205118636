ion-grid {
    padding: 0;
}

/* signup header */
.skillSearch__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.skillSearch__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.skillSearch__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.skillSearch__header--img {
    border: solid 6px #fff;
    border-radius: 15px;
    height: 120px;
    width: 120px;
    z-index: 10;
}

/* skillSelection text content */
.skillSearch__textContent {
    background-color: #fff;
}
.skillSearch__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 15px;
    padding-top: 50px;
    text-align: center;
}
.skillSearch__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.skillSearch__textContent--title span {
    color: var(--primary-color-green);
}
.skillSearch__textContent--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
}

/* search bar */
.skillSearch__searchbar {
    background-color: #fff;
}
.skillSearch__searchbar--container {
    align-items: center;
    display: flex;
    padding-bottom: 15px;
    justify-content: center;
    text-align: center;
}
.skillSearch__searchbar--input--wrap {
    background-color: #fff;
    border: none;
    position: relative;
    width: 80%;
    z-index: 10;
}
.skillSearch__searchbar--input {
    color: var(--primary-color-black);
    background-color: #f3f5f7;
    border: none;
    border-radius: 8px;
    outline: none;
    padding: 12px 16px;
    padding-left: 30px;
    width: 100%;
}
.skillSearch__searchbar--input::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    padding-left: 5px;
}
.skillSearch__searchbar--input--icon {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    top: 23px;
    left: 20px;
    position: absolute;
}

/* createTask btn */
.skillSearch__btn--container {
    background-color: #fff;
    width: 100%;
}
.skillSearch__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 30px;
    z-index: 10;
}
.skillSearch__btn {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    width: 80%;
}

/* skillSearch item */
.skillSearch__item--container {
    background-color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}
.skillSearch__item--col {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.skillSearch__item--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
}

.skillSearch__item--keyword {
    align-items: center;
    background-color: #f3f5f7;
    border: none;
    border-radius: 8px;
    color: var(--primary-color-black);
    display: flex;
    justify-content: center;
    margin: 5px;
    padding: 10px;
}
