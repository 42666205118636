/* absolute position images */
.editSkill__strokes--top {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    -webkit-filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
}
.editSkill__strokes--bottom {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

/* edit skill grid */
.editSkill__row {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
}

.editSkill__content--container {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.editSkill__content--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
    text-align: center;
}
.editSkill__content--title span {
    color: #10b080;
}
.editSkill__content--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0 10px;
    padding-bottom: 10px;
    text-align: center;
}

/* skill definition form */
.editSkill__form--container {
    padding: 0 15px;
    padding-bottom: 30px;
}
.editSkill__form--row {
    border-radius: 12px;
    background-color: #f3f5f7; /* zeplin container color */
    padding: 10px 10px;
    width: 100%;
}
.editSkill__form--input--wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
.editSkill__form--label {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: 0.75rem;
    font-weight: var(--font-regular);
    margin-bottom: 5px;
    margin-left: 5px;
}
.editSkill__form--input {
    color: var(--primary-color-black);
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    outline: none;
    width: 100%;
}
.editSkill__form--input::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}

/* editSkill btns */
.editSkill__btn--container {
    width: 100%;
}
.editSkill__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    z-index: 10;
}
.editSkill__btn--update {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    width: 80%;
}
.editSkill__btn--cancel {
    background: linear-gradient(106deg, #ff4242, #ff8c8c);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    width: 80%;
}
