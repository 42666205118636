.profileStates__tabs--container {
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
}

.profileStates__tabs--wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 28px 10px;
    z-index: 50;
}

.profileStates__tabs--btn--icon {
    color: #fff;
    font-size: 2.2rem;
}
