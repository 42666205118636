/* reviews btns */
.reviews__btn--wrap {
    /* let content determine the width */
    height: 45px;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    /* width: 250px; */
}

.reviews__btn--row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.reviews__btn {
    background-color: #ebeef1;
    border-radius: 15px;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    height: 100%;
    margin: 0;
    outline: none;
    padding: 0 6px;
    text-align: center;
}

.reviews__btn--active {
    background-color: #fff;
}

/* reviews btn more */
