.skillDef__textContent {
    background-color: #fff;
}
.skillDef__textContent--container {
    padding: 0 30px;
    text-align: center;
}
.skillDef__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.skillDef__textContent--title span {
    color: var(--primary-color-green);
}
.skillDef__textContent--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
}
