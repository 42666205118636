ion-grid {
    padding: 0;
}

/* signup header */
.skillConfirm__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.skillConfirm__header--container {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding-top: 125px;
    position: relative;
}

.skillConfirm__header--strokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.skillConfirm__header--img--wrap {
    border-radius: 15px !important;
    height: 75px;
    width: 75px;
    z-index: 10;
}
.skillConfirm__header--img {
    border-radius: 15px !important;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 10;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    -ms-border-radius: 15px !important;
    -o-border-radius: 15px !important;
}

/* skillSelection text content */
.skillConfirm__textContent {
    background-color: #fff;
}
.skillConfirm__textContent--container {
    padding: 0 30px;
    text-align: center;
}
.skillConfirm__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.skillConfirm__textContent--title span {
    color: var(--primary-color-green);
}
.skillConfirm__textContent--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
}

/* skillConfirm skills list */
.skillConfirm__list--container {
    background-color: #fff;
    padding: 0 15px;
    margin-bottom: 100px;
}
.skillConfirm__list {
    background-color: #f3f5f7; /* zeplin container color */
    border-radius: 15px;
    width: 100%;
}

.skillConfirm__item {
    align-items: flex-start;
    background-color: #f3f5f7; /* zeplin container color */
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 10px;
    z-index: 10;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.skillConfirm__content {
    align-items: flex-start;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-align: left;
}
.skillConfirm__content--skill {
    color: black;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}
.skillConfirm__content--keyword {
    color: var(--primary-color-dark-green);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}
.skillConfirm__content--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}
.skillConfirm__request--btn {
    align-items: center;
    background-color: #fff;
    border-left: solid 1px #f3f5f7;
    border-radius: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: var(--primary-color-dark-green);
    display: flex;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    height: 100%;
    margin: 0;
    outline: none;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    text-align: center;
    width: 100%;
}
.skillConfirm__request--btn--text,
.skillConfirm__request--btn--name {
    margin: 0;
    padding: 0;
}

/* newSKill btn */
.skillConfirm__newSkill--btn {
    align-items: flex-start;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 10px;
    z-index: 10;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.skillConfirm__newSkill--content {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-align: left;
}
.skillConfirm__newSkill--btn--text {
    color: var(--primary-color-dark-green);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
}

/* skillConfirm btn */
.skillConfirm__btn--container {
    background-color: #fff;
    width: 100%;
}
.skillConfirm__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    z-index: 10;
}
.skillConfirm__btn {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    color: #fff;
    width: 80%;
}

/* copyright */
.skillConfirm__footer {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
}
.skillConfirm__footer--strokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
}
