.btn {
    margin: 24px 16px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    /*color: var(--primary-color-black);*/
}

.icon {
    color: black;
    font-size: 2rem;
}
