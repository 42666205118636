ion-grid {
    padding: 0;
}

.editBio__grid {
    /* stretches screen to 100% */
    background-color: #fff;
}
.editBio__row {
    /* stretches screen to 100% */
    height: 100vh;
    width: 100%;
}
.editBio__container {
    /* centers all content */
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px;
    height: 100%;
}

.editBio__strokes--top {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    -webkit-filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
}
.editBio__strokes--bottom {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
}

.editBio__content--container {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.editBio__content--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
    text-align: center;
}
.editBio__content--title span {
    color: #10b080;
}
.editBio__content--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0 10px;
    padding-bottom: 10px;
    text-align: center;
}

.editBio__textarea--container {
    background-color: #f3f5f7;
    border-radius: 15px;
    padding: 0 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 90%;
}

.editBio__textArea--wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    border: solid 5px var(--primary-color-black);
}

.editBio__textarea {
    background-color: #fff;
    border: none;
    border-radius: 8px;
    height: 180px;
    outline: none;
    padding: 12px 12px;
    resize: none;
    width: 100%;
}
.editBio__form--textarea::placeholder {
    color: rgba(40, 41, 42, 0.3);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}

.editBio__skip--btn {
    border-bottom: solid 1px var(--primary-color-purple);
    color: var(--primary-color-purple);
}

/* editBio btns */
.editBio__btn--container {
    width: 100%;
}
.editBio__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    z-index: 10;
}
.editBio__btn--update {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    width: 80%;
}
.editBio__btn--cancel {
    background: linear-gradient(106deg, #ff4242, #ff8c8c);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    width: 80%;
}
