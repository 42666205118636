.inputError__error {
    color: var(--primary-color-red);
    font-size: var(--xsmall-text);
    font-weight: 600;
    padding: 6px 4px;
    margin: 0;
    text-align: left;
}
.inputError__error--center {
    text-align: center;
}
.inputError__error--span {
    color: var(--primary-color-red);
    font-size: var(--xsmall-text);
    font-weight: 300;
}
