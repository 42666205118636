.paymentInfo_description {
    text-align: center;
}

.paymentInfo__content {
    width: 100%;
    justify-content: space-between;
}

.edit_icon {
    color: black;
    font-size: 1.5rem;
    align-items: flex-end;
    justify-content: flex-end;
}
