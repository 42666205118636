ion-grid {
    padding: 0;
}

/* signup header */
.cancelTaskP__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.cancelTaskP__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.cancelTaskP__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.cancelTaskP__header--img {
    border-radius: 15px;
    border: solid 4px #fff;
    height: 120px;
    width: 120px;
    z-index: 10;
}
.cancelTaskP__back--btn {
    position: absolute;
    left: 11%;
    top: 10%;
    color: #fff;
}

/* skillSelection text content */
.cancelTaskP__textContent {
    background-color: #fff;
}
.cancelTaskP__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 10px;
    padding-top: 50px;
    text-align: center;
}
.cancelTaskP__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.cancelTaskP__textContent--title span {
    color: var(--primary-color-green);
}
.cancelTaskP__textContent--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
}

/* skill definition form */
.cancelTaskP__form--container {
    background-color: #fff;
    padding: 0 15px;
    padding-bottom: 30px;
}
.cancelTaskP__form--row {
    border-radius: 8px;
    background-color: #f3f5f7;
    padding: 10px 10px;
    width: 100%;
}
.cancelTaskP__form--input--wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
/* textarea */
.cancelTaskP__form--textarea {
    color: var(--primary-color-black);
    border: none;
    border-radius: 8px;
    height: 150px;
    outline: none;
    padding: 12px 12px;
    resize: none;
    width: 100%;
}
.cancelTaskP__form--textarea::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}

/* createTask btn */
.cancelTaskP__btn--container {
    background-color: #fff;
    width: 100%;
}
.cancelTaskP__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    z-index: 10;
}
.cancelTaskP__btn {
    background: linear-gradient(91deg, #d25a5a 0%, #ee6464 100%);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    width: 80%;
}

/* skill needed */
.cancelTaskP__container {
    padding: 0 15px;
    padding-bottom: 30px;
    width: 100%;
}

.cancelTaskP__row {
    background-color: #f3f5f7;
    border-radius: 15px;
    width: 100%;
}

.cancelTaskP__col {
    align-items: flex-start;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 10px;
}

.cancelTaskP__content {
    align-items: flex-start;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-align: left;
}

.cancelTaskP__content--skill {
    color: black;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}

.cancelTaskP__content--completed {
    color: var(--primary-color-purple);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
}
.cancelTaskP__content--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}

.cancelTaskP__request--btn {
    align-items: center;
    background-color: #fff;
    border-left: solid 1px #f3f5f7;
    border-radius: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: var(--primary-color-dark-green);
    display: flex;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    height: 100%;
    margin: 0;
    outline: none;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    text-align: center;
    width: 100%;
    -webkit-border-radius: none;
    -moz-border-radius: none;
    -ms-border-radius: none;
    -o-border-radius: none;
}

.cancelTaskP__request--btn--text,
.cancelTaskP__request--btn--name {
    margin: 0;
    padding: 0;
}
