ion-grid {
    padding: 0;
}

/* ying header */
.yingHeader {
    background: var(--primary-linear-gradient);
    width: 100%;
}
.yingHeader__img--strokes {
    top: 0;
    position: absolute;
    right: 0;
    object-fit: contain;
}
/* homeInterface */
.homeInterface__wrap {
    background-color: #f3f5f7;
}
/*above spend*/
.homeInterface {
    background-color: #fff !important;
    border: none !important;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -35px;
}
.homeInterface__btn--wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 15px;
}
.homeInterface__btn {
    background: var(--primary-linear-gradient-v2);
    border: none;
    border-radius: 8px;
    height: 45px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    width: 50%;
    /* margin-left: 15px; */
}

/* header buttons */
.header__buttons {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-right: 55px;
}

/* header gratitude image */
.homeGratitudePotImg {
    height: 148px;
    width: 100px;
    position: absolute;
    top: 60px;
    right: 45px;
}

/* edit button */
.homeInterface__edit {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -50px;
    position: relative;
    width: 100%;
}

/* goto profile/group button*/
.homeInterface__edit--col--btn {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -25px;
    z-index: 1;
}

.homeInterface__edit--btn {
    background-color: #fff;
    border: solid 2px #f3f5f7;
    border-radius: 8px;
    height: 45px;
    color: var(--primary-color-dark-green);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    margin: 0 8px;
    width: 170px;
    position: relative;
    top: -10px;
}
.homeInterface__edit--btn--icon {
    font-size: 1rem;
}

/* earn & spend */
.homeInterface__req--btns {
    padding-top: 30px;
    width: 100%;
}
.spendAndEarn__container {
    background-color: #fff !important;
    border: none !important;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -35px;
}
.spendAndEarn__title--row {
    padding-top: 5px;
    width: 100%;
}
.spend__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 10px 15px;
    margin-bottom: -15px;
}
.spendHeader {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 15px;
    padding-bottom: 10px;
}
.spend__title__button {
    justify-content: space-between;
    padding-right: 15px;
}
.spend__description {
    padding: 10px 15px;
    margin: 0;
}
.spend__description--wrap {
    word-wrap: break-word;
}
.spend__description--lineTwo {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    margin-top: -6px;
    margin-bottom: 0px;
}
.earn--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 10px 15px;
    margin-bottom: -15px;
}
.earnHeader {
    align-items: center;
    background-color: #f3f5f7;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    justify-content: center;
    width: 100%;
}
.earn__col--wrap {
    padding-top: 10px;
}
.earn__description {
    padding: 10px 15px;
    margin: 0;
}
/* .earn__description--wrap {
    display: flex;
    justify-content: center;
} */
.currencyDisplay__home__img {
    margin-right: -9px;
    margin-left: -9px;
    height: 30px;
    width: 30px;
}
.currencyDisplay__home__img__inline {
    margin-right: 0.4em;
    margin-left: 0.4em;
    display: inline;
    height: 30px;
    width: 30px;
    position: relative;
    bottom: -4px;
}

/* schedule */
.schedule__container {
    background-color: #fff !important;
    border: none !important;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -35px;
}

.schedule {
    background-color: #f3f5f7 !important;
    border-radius: 25px;
    margin-top: -40px;
}
.schedule__title--row {
    padding-top: px;
    width: 100%;
}
.schedule__row {
    margin: 5px 0;
    width: 100%;
}
.schedule__row--days {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
}

.schedule__col--header {
    background-color: #f3f5f7;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 20px;
}
.schedule__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding-top: 30px;
}
.schedule__notifications--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 10px 15px;
}
.schedule__filter {
    background-color: var(--primary-color-light-gray);
    border-radius: 10px;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    outline: none;
    padding: 8px 16px;
}

.schedule__col--days {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.schedule__spinner--wrap {
    align-items: center;
    background-color: #fff;
    border: none;
    display: flex;
    justify-content: center;
    padding-top: 100px;
    width: 100%;
}

.schedule__spinner {
    color: var(--primary-color-green);
    margin: 0;
    font-size: 10rem;
    height: 100px;
    width: 100px;
}
