.error-message {
    color: var(--primary-color-purple);
    text-align: center;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    width: 90%;
    margin: auto;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.show-more-btn {
    text-decoration: underline;
}
.error-icon {
    font-size: var(--large-text);
}
