ion-grid {
    padding: 0;
}
.topStrokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
/*FAQ Styling*/
.faqContainer {
    background-color: #fff;
    width: 100%;
    padding: 1px;
}
.faqWrap {
    border-radius: 15px;
    border: solid 10px #f6f6f6;
    padding: 10px;
    position: relative;
}
/* .faqWrap h1{
    font-family: "Poppins-SemiBold";
} */
.faqWrap h3,
label {
    /* font-family: "Poppins-SemiBold"; */
    color: #5f5eac;
}
.faqwrap button {
    background-color: white;
    font-size: 40px;
    color: #3ac299;
}
.faqContent {
    background-color: #fff;
    border-radius: 15px;
    padding: 0.1px 0.1px;
    text-align: center;
    z-index: 10;
    color: #908fc5;
    font-size: 22px;
}
.faqParagraph {
    text-align: left;
}
.faqTitle {
    color: #908fc5;
    font-size: 18.5px;
}
.faqInnerBtn {
    text-align: center;
    background-color: white;
    font-size: 30px;
    color: #3ac299;
}
.expandQuestionBtn {
    background-color: white;
    font-size: 40px;
    color: #3ac299;
    padding: 0.1px 0.1px;
    position: absolute;
    right: 0;
    top: calc(50% - 20px);
}
.closeFAQDivWrap {
    float: center;
}
.closeQuestionButton {
    background-color: white;
    font-size: 40px;
    color: #3ac299;
}
.hide {
    display: none;
}
.faqContactCenter {
    text-align: center;
}
/* copy/pasted css for arrow animation */
.menu__sidebar--backdrop {
    background-color: rgba(40, 41, 42, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
}

details {
    width: 100%;
    margin-top: 4px;
}

details > summary {
    background-color: #f3f5f7;
    border: none;
    border-radius: 12px;
    color: #28292a;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    font-size: 0.85rem;
    letter-spacing: -0.15px;
    margin-bottom: 10px;
    height: 45px;
    display: flex;
    align-items: center;
    list-style: none;
    outline: none;
}

/* REMOVE DEFAULT ARROW */
details > summary {
    list-style: none;
}
details > summary::-webkit-details-marker {
    display: none;
}

/* ANIMATION WHILE OPENING My Groups */
details[open] summary ~ * {
    animation: open 0.5s ease-in-out;
}
@keyframes open {
    0% {
        opacity: 0;
        margin-left: -25px;
    }
    100% {
        opacity: 1;
        margin-left: 0px;
    }
}

.transition--up {
    transform: rotate(0deg);
    transition: transform 0.3s;
}

.transition--down {
    transform: rotate(90deg);
    transition: transform 0.3s;
}
