ion-grid {
    padding: 0;
}

/* skills */
.skills {
    align-items: flex-start;
    display: flex;
    background: #fff !important;
    /* border-top-left-radius: 25px;
    border-top-right-radius: 25px; */
    flex-direction: column;
    margin-top: -20px;
    justify-content: flex-start;
    position: relative;
    width: 100%;
}
.skills,
.skillsOffered__row {
    width: 100%;
}

.skillsOffered__row:last-child {
    margin-bottom: 40px;
}

.skillsOffered__row {
    padding: 0px 20px;
}

.skills__col--title {
    padding: 15px 25px;
    width: 100%;
}
.skills__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
}
