ion-grid {
    padding: 0;
}

.reviewTask__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.reviewTask__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.reviewTask__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.reviewTask__header--img {
    border-radius: 8px;
    border: solid 4px var(--primary-color-white);
    height: 120px;
    width: 120px;
    z-index: 10;
}
.reviewTask__close--btn {
    position: absolute;
    right: 11%;
    top: 10%;
    color: #fff;
    z-index: 99;
}

/* skillSelection text content */
.reviewTask__textContent {
    background-color: #fff;
}
.reviewTask__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 15px;
    padding-top: 50px;
    text-align: center;
}
.reviewTask__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.reviewTask__textContent--title span {
    color: var(--primary-color-green);
}
.reviewTask__textContent--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 30px;
}
.reviewTask__textContent--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
    margin-top: 15px;
    padding: 0;
}

/* createTask btn */
.reviewTask__btn--container {
    background-color: #fff;
    padding-top: 200px;
    width: 100%;
}
.reviewTask__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    z-index: 10;
}
.reviewTask__btn {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    width: 80%;
}

/* IG: yingme_co */

/* star-review system */
input[type='radio'] {
    display: none;
}

.taskReview__container {
    background-color: #fff;
    padding: 0 15px;
    padding-bottom: 10px;
}
.stars {
    border-radius: 15px;
    background-color: #f3f5f7;
    padding: 0 10px;
    padding-bottom: 10px;
    width: 100%;
}

.stars__container {
    align-items: center;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    padding: 10px 0;
}
.star {
    color: var(--primary-color-inactive-gray);
    cursor: pointer;
    font-size: 2.2rem;
    transition: color 200ms;
    margin: 5px;
}

.reviewTask__textArea--wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.reviewTask__textarea {
    color: var(--primary-color-black);
    border: none;
    border-radius: 8px;
    height: 150px;
    outline: none;
    padding: 12px 12px;
    resize: none;
    width: 100%;
}
.reviewTask__form--textarea::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}
