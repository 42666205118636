.navBarSpacer {
    width: 100%;
    height: 65px;
}

.navBar {
    display: -ms-flexbox;
    display: flex;
    align-items: top;
    -ms-flex-align: center;
    justify-content: center;
    -ms-flex-pack: center;
    contain: strict;

    position: fixed;
    width: 100%;
    height: 65px;
    padding-bottom: 9px;
    box-sizing: content-box !important;
    -webkit-box-sizing: content-box !important;
    z-index: 10;
    bottom: 0;

    padding-inline-start: var(--ion-safe-area-left);
    -webkit-padding-start: var(--ion-safe-area-left);
    padding-inline-end: var(--ion-safe-area-right);
    -webkit-padding-end: var(--ion-safe-area-right);
    padding-left: var(--ion-safe-area-left);
    padding-right: var(--ion-safe-area-right);

    border-top: 1px solid rgba(0, 0, 0, 0.07);

    background-color: var(--ion-background-color, #fff);
    color: #000;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.navCorpus {
    width: 100%;
    height: auto;
}

.notification {
    position: relative;
    display: inline-block;
}

.notification IonIcon {
    position: relative;
    display: flex;
    top: calc(50% - 10px);
    left: calc(50% - 5px);
}

.unreadCount {
    position: absolute !important;
    bottom: 42px !important;
    left: 0px !important;
    text-align: left;
    font-size: 10px;
    font-weight: bold;
    color: #ffffff !important;
    min-width: 13px;
    min-height: 13px;
    border-radius: 3px;
    background: #ff3333 !important;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-left: 50%;
    vertical-align: middle;
    z-index: 2;
}
