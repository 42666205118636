ion-grid {
    padding: 0;
}

.onBio__grid {
    /* stretches screen to 100% */
    background-color: #fff;
}
.onBio__row {
    /* stretches screen to 100% */
    height: 100vh;
    width: 100%;
}
.onBio__container {
    /* centers all content */
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px;
    height: 100%;
}

.onBio__strokes--top {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    -webkit-filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
}
.onBio__strokes--bottom {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
}

.onBio__content--container {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.onBio__content--btn {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    width: 70%;
    z-index: 99;
}

.onBio__content--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
    text-align: center;
}
.onBio__content--title span {
    /* color: var(--primary-color-green); */
    color: #10b080;
}
.onBio__content--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0 10px;
    padding-bottom: 10px;
    text-align: center;
}

.onBio__textarea--container {
    background-color: #f3f5f7;
    border-radius: 15px;
    padding: 0 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 90%;
}

.onBio__textArea--wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    border: solid 5px var(--primary-color-black);
}

.onBio__textarea {
    background-color: #fff;
    border: none;
    border-radius: 8px;
    height: 180px;
    outline: none;
    padding: 12px 12px;
    resize: none;
    width: 100%;
}
.onBio__form--textarea::placeholder {
    color: rgba(40, 41, 42, 0.3);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}

.onBio__skip--btn {
    color: var(--primary-color-purple);
}
