ion-grid {
    padding: 0;
}

/* page containers */
.forgotPassword__grid {
    background-color: #fff;
    width: 100%;
}
.forgotPassword {
    height: 100vh;
    width: 100%;
}
.forgotPassword__back--btn {
    position: absolute;
    left: 11%;
    top: 5%;
    color: black;
}
/* page containers */

.forgotPassword__container {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 0;
    /* padding: 220px 25px; */
    position: relative;
    text-align: center;
}
.forgotPassword__img {
    padding-bottom: 30px;
    height: 100px;
    width: 100px;
}
.forgotPassword__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
}
.forgotPassword__title--span {
    /* color: var(--primary-color-green); */
    color: #10b080; /* zeplin green */
}
.forgotPassword__text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
}

.forgotPassword__form--wrap {
    margin: 0;
    padding: 0;
}
.forgotPassword__form {
    align-items: center;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.forgotPassword__form--input {
    background: none;
    background-color: none;
    border: none;
    border-radius: 0;
    border-bottom: solid 1px var(--primary-color-inactive-gray);
    color: var(--primary-color-black);
    font-size: var(--xsmall-text);
    outline: none;
    margin-bottom: 10px;
    padding: 12px 16px;
    text-align: center;
    width: 65%;
}
.forgotPassword__form--input::placeholder {
    color: rgba(40, 41, 42, 0.2); /* zeplin input placeholder-color */
    font-size: var(--xsmall-text);
    font-weight: var(--font-semi-bold);
}

.forgotPassword__form--submit {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    z-index: 99;
    width: 70%;
}

/* strokes */
.forgotPassword__strokes--top {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.forgotPassword__strokes--bottom {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
}

/* ! error handling */
.forgotPassword__form--input--error {
    border-bottom: solid 1px var(--primary-color-red);
}
.forgotPassword__error {
    color: var(--primary-color-red);
    font-size: var(--xsmall-text);
    font-weight: 600;
}
.forgotPassword__error--span {
    color: var(--primary-color-red);
    font-size: var(--xsmall-text);
    font-weight: 300;
}
