/* images / icons */
.authLanding__img {
    height: 75px;
    width: 75px;
}

/* container */
.authLanding__container {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.authLanding__row {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.authLanding__col {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

/* title */
.authLanding__title {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    margin-top: 100px;
    margin-bottom: 90px;
}

.authLanding__title--span {
    color: #10b080;
}

/* btns */
.authLanding__btn--solid {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    margin-bottom: 10px;
    outline: none;
    text-align: center;
    z-index: 99;
    width: 70%;
}

.authLanding__btn--transparent {
    background: none;
    border-radius: 8px;
    color: #10b080;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    z-index: 99;
    width: 70%;
}
