/* images / icons */
.contributePayPal__img {
    height: 75px;
    margin-bottom: 50px;
    width: 75px;
}

.contributePayPal__img--paypal {
    margin-top: 15px;
}

.contributePayPal__strokes--top {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.contributePayPal__strokes--bottom {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
}

/* container */
.contributePayPal__container {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.contributePayPal__row {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.contributePayPal__col {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

/* title */
.contributePayPal__title {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    font-size: 2.2rem;
    margin-bottom: 20px;
}

.contributePayPal__title--span {
    color: #10b080;
}

.contributePayPal__text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    text-align: center;
}

.contributePayPal__text--last {
    margin-bottom: 50px;
}

/* btns */
.contributePayPal__btn--back {
    position: absolute;
    left: 11%;
    top: 5%;
    color: black;
}

.contributePayPal__btn--solid {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    z-index: 99;
    width: 70%;
}
