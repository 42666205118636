.groupSkills__container {
    padding: 0 15px;
    padding-bottom: 50px;
    width: 100%;
}

.groupSkills__row {
    background-color: #f3f5f7;
    border-radius: 15px;
    width: 100%;
}

.groupSkills__col {
    align-items: flex-start;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 10px;
}

.groupSkills__content {
    align-items: flex-start;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-align: left;
}

.groupSkills__content--skill {
    color: black;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}

.groupSkills__content--completed {
    color: var(--primary-color-purple);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
}
.groupSkills__content--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}

.groupSkills__request--btn {
    align-items: center;
    background-color: #fff;
    border-left: solid 1px #f3f5f7;
    border-radius: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: var(--primary-color-dark-green);
    display: flex;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    height: 100%;
    margin: 0;
    outline: none;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    text-align: center;
    width: 100%;
    -webkit-border-radius: none;
    -moz-border-radius: none;
    -ms-border-radius: none;
    -o-border-radius: none;
}

.groupSkills__request--btn--text,
.groupSkills__request--btn--name {
    margin: 0;
    padding: 0;
}
