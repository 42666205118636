.landing__header {
    align-items: center;
    border-bottom: solid 1px rgb(212, 212, 212);
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 100%;
}
​ .landing__header--logo--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
}
​ .landing__header--logo {
    width: 48px;
    height: 50px;
    object-fit: contain;
}
​
/* landing title */
.landing__content--container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 85vh;
    justify-content: space-between;
    width: 100%;
}
.landing__text--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 12px 16px;
    padding-bottom: 30px;
    width: 100%;
}
.landing__text--title {
    font-family: var(--ion-font-poppins-family);
    font-size: 3.6rem;
    font-weight: 700;
    margin-top: 30px;
}
.landing__text--text {
    font-family: var(--ion-font-poppins-family);
    font-size: 1.4rem;
    font-weight: 300;
    padding: 0;
    margin-top: 20px;
}
.landing__title--span {
    color: var(--primary-color-green);
}
.landing__content--btn {
    background: var(--primary-linear-gradient-v2);
    border-radius: 15px;
    color: #fff;
    height: 60px;
    width: 320px;
}
​
/* policy */
.landing__policy--link--container {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    width: 100%;
}
.landing__policy--link--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    width: 100%;
}

.landing__policy--link {
    color: black;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
