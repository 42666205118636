/* reviews header */
.reviews {
    background-color: #f3f5f7 !important;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -20px;
    position: relative;
    width: 100%;
}
.reviews__row,
.reviews__btn--row {
    width: 100%;
}
.reviews__row--days {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.reviews__col--header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.reviews__title {
    border-bottom: none;
    color: black;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
}

.reviews__btn--more,
.reviews__btn--hire {
    background: var(--primary-linear-gradient);
    border-radius: 10px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 50px;
    margin: 0;
    outline: none;
    text-align: center;
    width: 100%;
}

.reviews__btn--more--wrap {
    padding-bottom: 50px;
}
