ion-grid {
    padding: 0;
}

/* wrappers */
.signup__container {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.signup__wrapper {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

/* back btn */
.signup__back--btn {
    position: absolute;
    left: 0%;
    top: 2%;
    color: var(--primary-color-black);
    z-index: 3;
}
.signup__back--btn--icon {
    font-size: 2rem;
    z-index: 3;
}

/* img */
.signup__logo {
    margin-bottom: 50px;
    height: 75px;
    width: 75px;
}

.signup__top--strokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}
.signup__bottom--strokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: fixed;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
    z-index: 1;
}

/* signup form */
.signup__form {
    background-color: #fff;
    background: none;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 25px;
    width: 100%;
    z-index: 2;
}
.signup__form--wrap {
    background-color: transparent;
    margin: 0;
    padding: 0;
    z-index: 99;
}
.signup__form--container {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding-bottom: 10px;
    width: 100%;
}

.signup__form--input--wrap {
    width: 80%;
}
.signup__form--input {
    background-color: #f6f6f6; /* zeplin input bg-color */
    border: none;
    border-radius: 8px;
    color: var(--primary-color-black);
    outline: none;
    padding: 12px 16px;
    margin-bottom: 10px;
    width: 100%;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.signup__form--input--password--wrap {
    position: relative;
}
.signup__form--input--password--icon {
    color: var(--primary-color-black);
    position: absolute;
    top: 25%;
    right: 5%;
}

.signup__form--input::placeholder {
    color: rgba(40, 41, 42, 0.2); /* zeplin input placeholder-color */
    font-size: var(--xsmall-text);
    font-weight: var(--font-semi-bold);
}

.signup__form--submit {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    width: 80%;
}

/* copyright */
.signup__copyright {
    align-items: center;
    display: flex;
    justify-content: center;
    /* padding-bottom: 100px; */
    position: relative;
    width: 100%;
    /*mess with z*/
    z-index: 10000;
    background-color: transparent;
}
.signup__copyright--container {
    text-align: center;
}
.signup__copyright--text {
    color: var(--primary-color-black);
    font-weight: 300;
    font-size: var(--xsmall-text);
}
.signup__copyright--link {
    border-bottom: solid 1px var(--primary-color-green);
    color: var(--primary-color-green);
    font-weight: var(--font-semi-bold);
    z-index: 10;
    background-color: rgba(0, 0, 0, 0);
    text-decoration: none;
}

/* error handling */
.signup__error--container {
    align-items: center;
    background-color: #fff;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 100%;
}
.signup__error--col {
    text-align: center;
    margin: 0;
    padding: 15px 0;
}

.signup__form--input--error {
    border: solid 1px var(--primary-color-red);
}
.signup__error {
    color: var(--primary-color-red);
    font-size: var(--xsmall-text);
    font-weight: 600;
    padding: 0;
    margin: 0;
}
.signup__error--span {
    color: var(--primary-color-red);
    font-size: var(--xsmall-text);
    font-weight: 300;
}
.signup__error--underline {
    border-bottom: solid 1px var(--primary-color-red);
}

.bottomStrokes {
    margin-top: 100px;
}

.agreementCheckbox {
    text-align: center;
    color: var(--primary-color-black);
    font-weight: 300;
    font-size: var(--xsmall-text);
    float: left;
}
.copyRightWrap {
    margin-top: -35px;
}
