ion-grid {
    padding: 0;
}

.completeTask__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.completeTask__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.completeTask__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.completeTask__header--img {
    border-radius: 15px;
    border: solid 4px #fff;
    height: 120px;
    width: 120px;
    z-index: 10;
}
.completeTask__close--btn {
    position: absolute;
    right: 11%;
    top: 10%;
    color: #fff;
    z-index: 99;
}

/* skillSelection text content */
.completeTask__textContent {
    background-color: #fff;
}
.completeTask__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 15px;
    padding-top: 50px;
    text-align: center;
}
.completeTask__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.completeTask__textContent--title span {
    color: var(--primary-color-green);
}
.completeTask__textContent--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin-top: 15px;
    padding: 0;
}

/* createTask btn */
.completeTask__btn--container {
    background-color: #fff;
    padding-top: 50px;
    width: 100%;
}
.completeTask__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    z-index: 10;
}
.completeTask__btn {
    background: var(--primary-linear-gradient-v2);
    color: #fff;
    border-radius: 12px;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    width: 100%;
}
.completeTask__btn--link {
    color: #fff;
    background: none;
    border-bottom: none;
    text-decoration: none;
    padding: 12px 16px;
    width: 100%;
}

/* form */
.completeTask__form--container {
    background-color: #fff;
    padding: 0 30px;
    margin-top: 15px;
    padding-bottom: 80px;
    text-align: center;
}
.completeTask__form--row {
    border-radius: 15px;
    background-color: #f3f5f7;
    padding: 10px 10px;
    width: 100%;
}
.completeTask__col {
    align-items: center;
    background-color: #f3f5f7;
    display: flex;
    justify-content: center;
    height: 150px;
    max-height: 150px;
    flex-direction: row;
    width: 100%;
}
.completeTask__input {
    text-align: center;
}
.completeTask__input {
    color: var(--primary-color-black);
    border: none;
    border-radius: 8px;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    padding: 12px 16px;
    outline: none;
    text-align: center;
    margin-bottom: 10px;
    height: 100px;
    font-size: 3rem;
    width: 40%;
    width: 100%;
}
.completeTask__input::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: 200;
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 3.6rem;
}
.completeTask__input:disabled {
    background-color: #fff;
}
.completeTask__input--label {
    font-family: var(--ion-font-poppins-family);
    font-weight: 500;
}

/* btn wrap */
.completeTask__btn--row {
    border-radius: 15px;
    background-color: #f3f5f7;
    width: 100%;
}

.completeTask__btn--wrap {
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.completeTask__btn--accept {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    width: 50%;
}
.completeTask__btn--msg {
    background: linear-gradient(106deg, #0091ff, #32c5ff);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    width: 50%;
}

/* completeTask btn waiting state */
.completeTask__btn--waiting {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    border: none;
    display: flex;
    color: #fff;
    border-radius: 12px;
    justify-content: center;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    position: relative;
    width: 100%;
}
.completeTask__btn--waiting--text {
    margin: 0;
    margin-left: 2.5px;
}
.completeTask__btn--waiting--spinner {
    color: #fff;
    margin: 0;
    margin-right: 2.5px;
}
