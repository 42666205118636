.stripe_onboarding_userimg--container {
    width: 100%;
    justify-content: center;
    margin: 10% 0 0 0;
}

.gratitude_pot_img--container {
    width: 100%;
    justify-content: center;
    margin: 5% 0;
}
.gratitude_pot_img {
    width: 30%;
    justify-content: center;
    margin: 5% 0;
}

.gratitude_pot_header--container {
    width: 100%;
    justify-content: center;
}

.gratitude_pot_header {
    border-bottom: none;
    color: var(--primary-color-black);
    font-weight: 800;
    padding: 0 5%;
    text-align: center;
}

.gratitude_pot_header--container span {
    color: var(--primary-color-green);
    white-space: nowrap;
}
.stripe_onboarding_userimg {
    width: 80px !important;
    height: 80px !important;
}

.gratitude_pot_text--container {
    justify-content: center;
    align-items: center;
}

.gratitude_pot_text {
    text-align: center;
    padding: 0 5%;
}
.gratitude_pot_text span {
    font-weight: 700;
}
.stripe_onboarding_grid {
    justify-content: center;
    align-self: center;
    font-family: var(--ion-font-poppins-family);
}

.stipe_activate_btn {
    border-radius: 8px;
    background: var(--primary-linear-gradient-v2);
    width: 80%;
    font-weight: 600;
}
.stipe_activate_btn--container {
    width: 100%;
    display: flex;
    justify-content: center;
}
.stripe_skip_btn--container {
    color: var(--primary-color-purple) !important;
    text-align: center;
    width: 100%;
    margin-top: 1%;
    font-weight: 600;
}
