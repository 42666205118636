ion-grid {
    padding: 0;
}

/* editHome header */
.homeEditHeader__row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    width: 100%;
}
.homeEditHeader__img--wrap {
    background-color: #fff !important;
    border-radius: 15px;
    border: solid 7px #fff !important;
    height: 125px;
    overflow: hidden;
    position: absolute;
    width: 125px;
    left: 25px;
    top: -100px;
    padding: 0;
    z-index: 2;
}
.homeEditHeader__img {
    border-radius: 0;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}

/* profile header */
.homeEditHeader {
    background: var(--primary-linear-gradient);
    width: 100%;
}

.homeEditHeader__col--top {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    padding: 60px 25px 0px 25px;
    position: relative;
}
.homeEditHeader__col--img {
    margin-top: 100px;
    position: relative;
}
.homeEditHeader__img--strokes {
    top: 0;
    position: absolute;
    right: 0;
    object-fit: contain;
}

.homeEditHeader__col--bottom {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: left;
    padding-right: 25px;
}
.homeEditHeader__name {
    border-bottom: none;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    padding-bottom: 30px;
}
.homeEditHeader__btn {
    color: var(--primary-color-white);
}

/* save button */
.homeEdit__save--btn--container {
    background: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -20px;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
}

.homeEdit__save--btn--wrap {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -25px;
}

.homeEdit__save--btn {
    background-color: #fff;
    border: solid 2px #f3f5f7;
    border-radius: 8px;
    height: 45px;
    color: var(--primary-color-dark-green);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    margin: 0 8px;
    width: 170px;
}
.homeEdit__btn--icon {
    font-size: 1rem;
}

.homeEdit__btn--back {
    position: absolute;
    top: 5%;
    left: 8%;
    color: #fff;
}

/* home-edit title */
.homeEdit__title {
    background-color: #fff;
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
    margin-top: 15px;
    padding: 10px 15px;
}

.homeEdit__img--badge {
    background: linear-gradient(135deg, #6236ff, #987df7);
    border-radius: 100px;
    border: solid 2px #fff;
    height: 100px;
    padding-left: 15px;
    padding-top: 15px;
    position: absolute;
    right: -50px;
    bottom: -50px;
    width: 100px;
}
.homeEdit__img--badge--icon {
    color: var(--primary-color-white);
    font-size: 1.6rem;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
}

/* home edit bio */
.homeEdit__editBio--row {
    background-color: #f3f5f7; /* zeplin container color */
    border-radius: 15px;
    width: 100%;
}
.homeEdit__editBio--item {
    align-items: flex-start;
    background-color: #f3f5f7; /* zeplin container color */
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 10px;
    z-index: 10;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.homeEdit__editBio--item--content {
    align-items: flex-start;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-align: left;
}
.homeEdit__editBio--item--content--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}
.homeEdit__editBio--btn {
    align-items: center;
    background-color: #fff;
    border-left: solid 1px #f3f5f7;
    border-radius: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: var(--primary-color-dark-green);
    display: flex;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    height: 100%;
    margin: 0;
    outline: none;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    text-align: center;
    width: 100%;
}
.homeEdit__editBio--btn--text,
.homeEdit__editBio--btn--name {
    margin: 0;
    padding: 0;
}

/* homeEdit list container*/
.homeEdit__list--container {
    background-color: #fff;
    padding: 0 15px;
}

.homeEdit__list {
    background-color: #f3f5f7;
    border-radius: 15px;
    margin-bottom: 50px;
    width: 100%;
}

.homeEdit__newSkill--btn {
    align-items: flex-start;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 10px;
    z-index: 10;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.homeEdit__newSkill--content {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-align: left;
}
.homeEdit__newSkill--btn--text {
    color: var(--primary-color-dark-green);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
}

.homeEdit__delete--btn {
    background: linear-gradient(106deg, #ff4242, #ff8c8c);
}

.homeEdit__content--slide--wrap {
    border: none;
    border-radius: 15px;
    width: 100%;
}

.exit-modal-wrapper .modal-wrapper {
    height: 26vh;
    width: 80vw;
    margin: auto;
}

.exit-modal-text {
    color: black;
    padding: 2rem;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    text-align: center;
}

.modal-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-button {
    width: 100%;
    height: 50px;
    border-radius: 13px;
    font-size: 1rem;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
}

.confirm-button {
    background-image: var(--primary-linear-gradient-v2);
    color: white;
    margin-bottom: 16px;
}

.cancel-button {
    background-color: white;
    color: var(--primary-color-green);
}
