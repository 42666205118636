/* taskItem */
.groupTaskItem__row {
    align-items: center;
    background-color: #f3f5f7;
    display: flex;
    justify-content: center;
    width: 100%;
}
.groupTaskItem__row:last-child {
    padding-bottom: 10px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.groupTaskItem {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    position: relative;
}

.groupTaskItem__info {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.groupTaskItem__img--wrap {
    border-radius: 15px;
    border: solid 5px #fff;
    overflow: hidden;
    position: absolute;
    width: 75px;
    height: 75px;
    left: -10px;
    top: -50px;
    z-index: 2;
    padding: 0 !important;
    background-color: #fff;
    margin-left: 5px;
    /* box-shadow: -5px 5px 15px grey; */
}
.groupTaskItem__img {
    border-radius: 0;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}
.groupTaskItem__info--title {
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 0;
}
.groupTaskItem__info--user {
    color: var(--primary-color-purple);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}

.groupTaskItem__description--wrap {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.groupTaskItem__description {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}
