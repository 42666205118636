ion-grid {
    padding: 0;
}

/* search bar & header */
.tasksPosted-ionSearchBar {
    --color: var(--primary-color-green);
    --background: #f6f6f6;
    --placeholder-color: var(--primary-color-inactive-gray);
    --icon-color: var(--primary-color-inactive-gray);
    --clear-button-color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}

.tasksPosted-header {
    padding-top: 18px;
}

.tasksPosted-toolbar {
    padding-bottom: 16px;
}

.tasksPosted__close--btn {
    color: var(--primary-color-green);
    margin-right: 15px;
    margin-left: 0px;
    font-size: 2rem;
}

.tasksPosted__container {
    background-color: #f3f5f7;
    /* padding-top: 120px; */
}

.tasksPosted__searchbar {
    background-color: #fff;
    box-shadow: 0px 0px 8px 0px rgba(26, 26, 26, 0.25);
    left: 0;
    padding-bottom: 10px;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 999;
}
.tasksPosted__searchbar--container {
    align-items: center;
    display: flex;
    /* padding: 0 30px; */
    padding-bottom: 2px;
    padding-top: 40px;
    padding-left: 10px;
    text-align: center;
}
.tasksPosted__searchbar--input--wrap {
    background-color: #fff;
    border: none;
    position: relative;
    width: 100%;
    z-index: 10;
}
.tasksPosted__searchbar--input--icon {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    top: 24px;
    left: 20px;
    position: absolute;
}
.tasksPosted__searchbar--input {
    color: var(--primary-color-green);
    background-color: #f6f6f6;
    border: none;
    border-radius: 8px;
    outline: none;
    padding: 12px 16px;
    padding-left: 30px;
    width: 100%;
}
.tasksPosted__searchbar--input::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    padding-left: 5px;
}

/* tasksPosted filters */
.tasksPosted__filters--container {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    padding-left: 15px;
    padding-right: 7px;
}
.tasksPosted__filters--wrap {
    align-items: center;
    background-color: #fff;
    border: none;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin: 0;
    padding: 0;
}
.tasksPosted__filters--btn {
    color: black;
    background: none;
    border: solid 1px black;
    border-radius: 10px;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    padding: 0;
    margin-right: 6px;
}

.tasksPosted__filters--btn--active {
    color: var(--primary-color-green);
    background: none;
    border: solid 1px var(--primary-color-green);
    border-radius: 10px;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    padding: 0;
    margin-right: 6px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tasksPosted__filters--wrap::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tasksPosted__filters--wrap {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* opportunities list text-title*/
.tasksPosted__title--wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 15px;
}

.tasksPosted__title {
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
}

.tasksPosted__btn {
    background-color: #fff;
    border-radius: 12px;
    color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    outline: none;
}

.tasksPosted__btn--icon {
    color: var(--primary-color-green);
}

/* text */
.tasksPosted__text {
    margin-top: 20%;
    display: flex;
    justify-content: center;
    text-align: center;
}

/* tasks posted items */
.tasksPostedItem__row {
    align-items: center;
    background-color: #f3f5f7;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    width: 100%;
}
.tasksPostedItem__row:last-child {
    padding-bottom: 10px;
}

.tasksPostedItem__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
    padding-left: 15px;
}

.tasksPostedItem {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    position: relative;
}

.tasksPostedItem__info {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.tasksPostedItem__img--wrap {
    border: none;
    border-radius: 15px;
    height: auto;
    padding: 0;
    margin-left: 5px;
    position: absolute;
    left: -10px;
    top: -50px;
    width: 75px;
    overflow: hidden;
}
.tasksPostedItem__img {
    border: solid 4px #fff;
    border-radius: 15px;
    height: auto;
    overflow: hidden;
    width: 100%;
}
.tasksPostedItem__info--name {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 0;
}
.tasksPostedItem__info--date {
    color: var(--primary-color-purple);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}
.tasksPostedItem__info--descr {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}
.tasksPostedItem__info--credits--earned {
    color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}
.tasksPostedItem__info--credits--paid {
    color: var(--primary-color-red);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}

/* btns */
.tasksPosted__item--btn--wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
.tasksPosted__item--btn--manage {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    width: 100%;
}
.tasksPosted__item--btn--view {
    border: solid 2px var(--primary-color-green) !important;
    border-radius: 8px;
    color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    width: 100%;
}

.tasksPosted__item--completed--btn--view {
    border: solid 2px var(--primary-color-green) !important;
    border-radius: 8px;
    color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    width: 100%;
}
