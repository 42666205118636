ion-grid {
    padding: 0;
}

.profile__wrap {
    background-color: #f3f5f7;
}

.groupMembers {
    background: #fff;
    /* border-top-left-radius: 25px;
    border-top-right-radius: 25px; */
    margin-top: 0px;
    margin-bottom: 20px;
    /* padding-left: 15px; */
    position: relative;
    justify-content: center;
    width: 100%;
}

.groupMembers ion-icon {
    font-size: 30px;
    padding-left: 5px;
}

.group-members--btn {
    background: var(--primary-linear-gradient-v2);
    border: none;
    border-radius: 8px;
    height: 45px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
}

/* skills */
.skills {
    background: #fff;
    /* border-top-left-radius: 0px;
    border-top-right-radius: 0px; */
    margin-top: -20px;
    position: relative;
    width: 100%;
}
.skills,
.skillsOffered__row {
    width: 100%;
}

.skillsOffered__row:last-child {
    margin-bottom: 40px;
}

.skillsOffered__row {
    padding: 0px 20px;
}

.skills__col--title {
    padding: 15px 25px;
    width: 100%;
}
.skills__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
}

/* reviews header */
.opps {
    background-color: #f3f5f7;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -20px;
    position: relative;
    width: 100%;
}
.opps__header--wrap {
    margin: 0;
    padding: 0;
    width: 100%;
}

.opps__header--col {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}
.opps__header--title {
    border-bottom: none;
    color: black;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: 1.2rem;
    margin: 0;
}
.opps__header--btn {
    background: var(--primary-linear-gradient-v2);
    border: none;
    border-radius: 8px;
    height: 45px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
}

/* profile details */
.profileBio {
    background: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -20px;
    position: relative;
    width: 100%;
}

.profileBio__col--btn {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -25px;
}

.profileBio__btn {
    background: linear-gradient(106deg, #0091ff, #32c5ff);
    border: none;
    border-radius: 8px;
    height: 45px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    margin: 0 8px;
    width: 170px;
}
.profileBio__btn--icon {
    font-size: 1rem;
}

/* profileBio bio section */
.profileBio__row--bio {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    width: 100%;
    margin-top: -15px;
}
.profileBio__col--bio {
    text-align: left;
    width: 100%;
}
.profileBio__bio {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
}
