.completedTasks__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.completedTasks__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.completedTasks__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.completedTasks__header--img {
    border: solid 6px #fff;
    border-radius: 15px;
    height: 120px;
    width: 120px;
    z-index: 10;
}

/* completedTasks text content */
.completedTasks__textContent {
    background-color: #fff;
}
.completedTasks__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 15px;
    padding-top: 50px;
    text-align: center;
}
.completedTasks__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.completedTasks__textContent--title span {
    color: var(--primary-color-green);
}

/* completed Tasks searchbar */
.completedTasks__searchbar {
    background-color: #fff;
    text-align: center;
}
.completedTasks__searchbar--container {
    align-items: center;
    display: flex;
    padding: 0 30px;
    padding-bottom: 15px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.completedTasks__searchbar--input--wrap {
    background-color: #fff;
    border: none;
    position: relative;
    width: 90%;
    z-index: 10;
}
.completedTasks__searchbar--input {
    color: var(--primary-color-black);
    background-color: #f6f6f6;
    border: none;
    border-radius: 8px;
    outline: none;
    padding: 12px 16px;
    padding-left: 30px;
    width: 100%;
}
.completedTasks__searchbar--input::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    padding-left: 5px;
}
.completedTasks__searchbar--input--icon {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    top: 24px;
    left: 20px;
    position: absolute;
}

/* completedTasks Item */
.completedTasksItem__row {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}
.completedTasksItem__row:last-child {
    padding-bottom: 10px;
}

.completedTasksItem__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
    padding-left: 15px;
}

.completedTasksItem {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    position: relative;
}

.completedTasksItem__info {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.completedTasksItem__img--wrap {
    border: none;
    border-radius: 15px;
    height: auto;
    padding: 0;
    margin-left: 5px;
    position: absolute;
    left: -10px;
    top: -50px;
    width: 75px;
    overflow: hidden;
}
.completedTasksItem__img {
    border: solid 4px #fff;
    border-radius: 15px;
    height: auto;
    overflow: hidden;
    width: 100%;
}
.completedTasksItem__info--name {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 0;
}
.completedTasksItem__info--date {
    color: var(--primary-color-purple);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}
.completedTasksItem__info--descr {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}
.completedTasksItem__info--credits--earned {
    color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}
.completedTasksItem__info--credits--paid {
    color: var(--primary-color-red);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0;
}

.completedTasksItem__btn--wrap {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.completedTasksItem__btn--view,
.completedTasksItem__btn--chat {
    border-radius: 8px;
    width: 50%;
}
.completedTasksItem__btn--inProgress {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    color: #fff;
    width: 100%;
}
.completedTasksItem__btn--chat {
    background: linear-gradient(106deg, #0091ff, #32c5ff);
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
}
.completedTasksItem__btn--view {
    background: var(--primary-linear-gradient-v2);
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
}
