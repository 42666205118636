ion-grid {
    padding: 0;
}

/* page containers */
.taskConfirm__grid {
    height: 95vh;
}
.taskConfirm {
    background: var(--primary-linear-gradient-v2);
    height: 100%;
    width: 100%;
}
.taskConfirm__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0 30px;
    text-align: center;
}
.taskConfirm__back--btn {
    position: absolute;
    left: 11%;
    top: 5%;
    color: black;
}

.taskConfirm__strokes--top {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.taskConfirm__strokes--bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
}

/* task confirm content */
.taskConfirm__img--wrap {
    background: none;
    border: solid 5px #fff;
    border-radius: 8px;
    height: 105px;
    overflow: hidden;
    width: 105px;
    z-index: 2;
}
.taskConfirm__img {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.taskConfirm__title {
    border-bottom: none;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    z-index: 2;
}
.taskConfirm__text {
    border-bottom: none;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
    margin: 0;
    z-index: 2;
}

/* imgs container */
.taskConfirm__img--container {
    background: none;
    border: none;
    display: flex;
}
.taskConfirm__img--wrap--left {
    transform: rotate(-15deg);
    z-index: 2;
}
.taskConfirm__img--wrap--right {
    transform: rotate(15deg);
    z-index: 2;
}

.taskConfirm__btn {
    background: var(--primary-color-dark-green);
    border-radius: 8px;
    margin-top: 20px;
    width: 80%;
    z-index: 2;
}
