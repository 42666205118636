ion-grid {
    padding: 0;
}

/* signup header */
.locationModal__btn--close {
    background-color: transparent;
    color: var(--primary-color-black);
    font-size: 1.6rem;
    position: absolute;
    right: 0%;
    top: 5%;
    z-index: 10;
}
.locationModal__btn--close--icon {
    color: var(--primary-color-black);
}
.locationModal__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.locationModal__header--container {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding-top: 120px;
    padding-bottom: 30px;
    position: relative;
}

.locationModal__header--strokes {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.locationModal__header--img {
    border-radius: 8px;
    border: solid 4px var(--primary-color-white);
    box-shadow: 0px 0px 10px 0px rgba(26, 26, 26, 1);
    height: 75px;
    width: 75px;
    z-index: 10;
}

/* skill badge */
.locationModal__badge--container {
    align-items: center;
    display: flex;
    background-color: #fff;
    justify-content: center;
    width: 100%;
}
.locationModal__badge {
    border-radius: 10px;
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    height: 50px;
    padding: 0;
    text-align: center;
    z-index: 10;
}

/* skill definition form */
.locationModal__form--container {
    background-color: #fff;
    padding: 0 15px;
    padding-bottom: 20px;
}
.locationModal__form--row {
    border-radius: 15px;
    background-color: #f3f5f7; /* zeplin container color */
    padding: 10px 10px;
    width: 100%;
}
.locationModal__col {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 10px;
}
.locationModal__col--active {
    background-color: #0091ff3a;
}

.locationModal__content {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-align: left;
}
.locationModal__content--active {
    background-color: #0091ff3a;
}

.locationModal__content--skill {
    color: black;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}
.locationModal__content--skill--active {
    color: #0091ff;
}

.locationModal__content--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}
.locationModal__content--text--active {
    color: #0091ff;
}

/* location tyoe divider */
/type divider / .locationModal__typeDivider {
    align-items: center;
    background-color: #f3f5f7; /* zeplin container color */
    display: flex;
    justify-content: center;
    width: 100%;
}
.locationModal__typeDivider__text {
    border-bottom: 1px solid var(--primary-color-inactive-gray);
    color: var(--primary-color-inactive-gray);
    line-height: 0.1em;
    text-align: center;
}
.locationModal__typeDivider__text--span {
    background-color: #f3f5f7; /* zeplin container color */
    padding: 0 10px;
}

/* continue btn */
.locationModal__btn--container {
    background-color: #fff;
    width: 100%;
}
.locationModal__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    z-index: 10;
}
.locationModal__btn {
    background: var(--primary-linear-gradient-v2);
    border-radius: 8px;
    color: #fff;
    width: 80%;
}
