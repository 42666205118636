ion-grid {
    padding: 0;
}

/* taskDetails header */
.requestDetails__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.requestDetails__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 100px;
    padding-bottom: 30px;
    position: relative;
}

.requestDetails__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.requestDetails__back--btn {
    position: absolute;
    left: 2%;
    top: 8%;
    color: #fff;
}
.requestDetails__back--btn--icon {
    font-size: 2rem;
}

/* taskDetails description */
.requestDetails__header--desc--wrap {
    padding: 0 15px;
}
.requestDetails__header--desc--taskName {
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-bold);
    padding: 0;
}
.requestDetails__header--desc--taskName--span,
.requestDetails__header--desc--taskDescr--span {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
}
.requestDetails__header--desc--taskDescr {
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-bold);
    padding: 0;
}
.requestDetails__header--desc--user {
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-bold);
    padding: 0;
    padding-left: 10px;
}
.requestDetails__header--descr--img {
    border-radius: 12px;
    border: solid 4px #fff;
    overflow: hidden;
    height: 50px;
    width: 50px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.requestDetails__header--img--wrap {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 0 15px;
    padding-bottom: 75px;
}

/* taskDetails skills list */
.requestDetails__skills {
    background-color: #fff;
    /* margin-bottom: 30px; */
}
.requestDetails__skills--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 15px;
    text-align: left;
}
.requestDetails__skills--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}

/* tp details participants */
.requestDetails__users--container {
    background-color: #fff;
    width: 100%;
}
.requestDetails__users {
    align-items: center;
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 15px;
    width: 100%;
}

.requestDetails__user {
    align-items: center;
    background-color: #fff;
    border: solid 4px #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.requestDetails__user--img {
    display: flex;
    border-radius: 15px;
    height: 65px;
    width: 65px;
    overflow: hidden;
}
.requestDetails__user--name {
    margin: 0;
    padding: 5px 0;
}

/* taskDetails location */
.requestDetails__location {
    padding: 10px 15px;
    width: 100%;
}

.requestDetails__map--wrap {
    background-color: #f3f5f7;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 30px 10px;
    margin-bottom: 10px;
    width: 100%;
}

.requestDetails__location--title {
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
}

.requestDetails__location--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-regular);
    font-size: var(--xsmall-text);
    padding: 0;
    margin: 0;
}

/* map container */
.requestDetails__map--container {
    border-radius: 25px;
    height: 300px;
    overflow: hidden;
    padding: 0 15px;
    width: 100%;
}

/* icon container */
.requestDetails__icon--container {
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 0 15px;
    width: 100%;
}

.requestDetails__icon--wrap {
    align-items: center;
    border-radius: 12px;
    background-color: var(--primary-color-off-white);
    display: flex;
    justify-content: center;
    height: 200px;
    width: 100%;
}

.requestDetails__icon {
    color: var(--primary-color-inactive-gray);
    font-size: 4rem;
}

/* clipboard */
.requestDetails__location--clipboard {
    color: #6236ff;
    font-size: 1.6rem;
    position: absolute;
    top: 0;
    right: 0;
}

.requestDetails__location--clipboard--toast {
    --background: linear-gradient(135deg, #6236ff, #987df7);
    --color: #fff;
}
