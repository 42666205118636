.create-task-message {
    color: var(--primary-color-green);
    text-decoration: underline;
    font-weight: var(--font-semi-bold);
}
.empty-message {
    padding: 0px 15px;
    font-family: var(--ion-font-poppins-family);
    text-align: center;
}
.balance-heading {
    font-family: var(--ion-font-poppins-family);
    font-size: 1.4em;
    margin: 0;
}
.currency-sign {
    font-family: var(--ion-font-poppins-family);
    font-size: 2.4em;
    margin-bottom: -3px;
    margin: 0;
}
.pending {
    font-family: var(--ion-font-poppins-family);
    font-size: 1.4em;
    margin: 0;
    margin-top: 22px;
    margin-left: 20px;
}
