.reviews__col {
    align-items: flex-start;
    background-color: #f3f5f7;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.reviews__avatar--img {
    border: solid 2px var(--primary-color-white);
    border-radius: 10px;
    height: 35px;
    width: 35px;
}
.reviews__content {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-align: left;
}

.reviews__content--title--wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    width: 100%;
}
.reviews__content--title {
    color: black;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding-left: 2px;
}
.reviews__content--title--date {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}

.reviews__content--stars--wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 0;
    padding: 12px 0;
    width: 100%;
}
.reviews__content--stars {
    color: var(--primary-color-yellow);
    margin: 0;
    padding: 0;
}

.reviews__content--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}
