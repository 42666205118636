ion-grid {
    padding: 0;
}

/* signup header */
.calend__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.calend__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.calend__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.calend__header--img {
    border: solid 6px #fff;
    border-radius: 15px;
    height: 120px;
    width: 120px;
    z-index: 10;
}

/* skillSelection text content */
.calend__textContent {
    background-color: #fff;
}
.calend__textContent--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 10px;
    padding-top: 50px;
    text-align: center;
}
.calend__textContent--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}
.calend__textContent--title span {
    color: var(--primary-color-green);
}
.calend__textContent--text {
    color: var(--primary-color-light-white);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    padding: 0;
}

/* skill definition form */
.calend__form--container {
    background-color: #fff;
    padding: 0 15px;
    padding-bottom: 30px;
}
.calend__form--row {
    border-radius: 15px;
    background-color: #f3f5f7;
    padding: 10px 10px;
    width: 100%;
}
.calend__form--input--wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
.calend__form--label {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: 0.75rem;
    font-weight: var(--font-regular);
    margin-bottom: 5px;
    margin-left: 5px;
}
.calend__form--input {
    color: var(--primary-color-black);
    border: none;
    border-radius: 15px;
    padding: 12px 16px;
    outline: none;
    width: 100%;
}
.calend__form--input::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}

/* form btns */
.calend__form--btns--container {
    align-items: center;
    display: flex;
    flex-direction: row;
}
.calend__form--btn {
    background-color: #fff;
    border: none;
    border-radius: 15px;
    color: var(--primary-color-green);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    width: 100%;
}

/* createTask btn */
.calend__btn--container {
    background-color: #fff;
    width: 100%;
}
.calend__btn--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 60px;
    z-index: 10;
}
.calend__btn {
    background: var(--primary-linear-gradient-v2);
    border-radius: 15px;
    color: #fff;
    width: 80%;
}

/* calendar styles */
.calendar__container {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    width: 100%;
}
.calendar__wrap {
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
}
.calendar__row {
    margin-top: 5px;
    border-radius: 0px;
    background-color: #f3f5f7;
    padding: 10px;
    padding-top: 0px;
    width: 100%;
    -webkit-border-radius: 0px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    z-index: 3;
}
.calendar__text--wrap {
    background-color: #f3f5f7;
    text-align: center;
    padding: 20px 10px;
    margin-top: -15px;
    z-index: 1;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}
.calendar__text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-regular);
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
}
.calendar__text--span {
    color: #0091ff;
    border-bottom: solid 1px #0091ff;
}
.react-calendar {
    /* width: 350px; */
    max-width: 100%;
    background-color: #fff;
    border: none;
    border-radius: 15px;
    font-family: var(--ion-font-poppins-family);
    line-height: 1.125em;
    padding: 0 !important;
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    /*calendar btns*/
    /* background-color: #fff; */
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    /*calendar header*/
    background-color: #f3f5f7;
    height: 44px;
    margin-bottom: 0;
    padding: 0;
}
.react-calendar__navigation button {
    /*navigation June 2020*/
    background: none;
    font-family: var(--ion-font-poppins-family);
    font-weight: 100;
    font-size: 25px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__navigation__label__labelText {
    font-size: 13.6px;
    min-width: 44px;
    background: none;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
}

.react-calendar__navigation__label {
    margin-bottom: 4px !important;
}

.react-calendar__navigation button[disabled] {
    background-color: #f3f5f7;
}
.react-calendar__month-view__weekdays {
    /*week days container*/
    color: var(--primary-color-light-white);
    background-color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
    color: var(--primary-color-black);
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    max-width: 100%;
    text-align: center;
    padding: 1em 0.5em;
    background: none;
}
.react-calendar__tile:disabled {
    /*styles days text on disabled*/
    background-color: #fff;
    color: var(--primary-color-light-white);
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: rgba(16, 176, 128, 0.205);
}
.react-calendar__tile--now {
    /* color: #fff; */
    color: var(--primary-color-dark-green);
    border-radius: 15px;
    /* background: var(--primary-color-dark-green); */
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: rgba(16, 176, 128, 0.205);
    border-radius: 15px;
}
.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    background: rgba(16, 176, 128, 0.205);
    border-radius: 15px;
    color: var(--primary-color-dark-green);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: rgba(16, 176, 128, 0.205);
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

/* tbd button */
.startDate__form--row-tbd {
    margin-top: -10px;
    border-radius: 0px;
    padding-top: 0px;
    background-color: #f3f5f7;
    padding: 5px;
    width: 100%;
    -webkit-border-radius: 0px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    z-index: 2;
}
