/* img cropper */
.container {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}
.content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 50px;
    z-index: 99;
}
.content h1 {
    border-bottom: none;
    color: var(--primary-color-black, black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
    text-align: center;
}
.content h1 span {
    color: #10b080;
}
.text {
    color: var(--primary-color-black, black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0 10px 20px 10px;
    text-align: center;
}
.uploadText {
    color: var(--primary-color-light-gray);
    font-size: var(--xsmall-text);
    font-weight: 500;
    text-align: center;
}

.cropper {
    border-radius: 15px;
    background-color: #f3f5f7;
    border: none;
    overflow: hidden;
    margin-bottom: 30px;
}
.preview {
    box-shadow: 0px 0px 10px 0px rgba(26, 26, 26, 1);
    border-radius: 15px;
    background-color: #f3f5f7;
    border: none;
    overflow: hidden;
    margin-bottom: 30px;
}

.preview canvas {
    border-radius: 15px;
    max-width: 120px;
}

.btn {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: var(--primary-color-white);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    margin-top: 20px;
    width: 70%;
    z-index: 999;
}
