ion-grid {
    padding: 0;
}

/* signup header */
.confirmPost__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.homeHeader {
    background: var(--primary-linear-gradient);
    width: 100%;
    border-bottom-left-radius: 25px;
}

.confirmPost__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    padding-top: 125px;
    padding-bottom: 30px;
    position: relative;
}

.confirmPost__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    border-bottom-left-radius: 20px;
}
.confirmPost__header--img {
    border: solid 6px #fff;
    border-radius: 15px;
    height: 120px;
    width: 120px;
    z-index: 10;
}

.alignAdmin {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.fixed {
    color: black;
    width: 95%;
}

.bigCenter {
    display: flex;
    justify-content: center;
}

.adminGroupItems {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    p {
        padding-left: 0.25em;
    }
}

.bendy {
    display: flex;
    flex-direction: column;
    margin-top: -1.25em;
    z-index: 2;
    position: relative;
    background: white;
    border-top-left-radius: 1.25em;
    border-top-right-radius: 1.25em;
}

.slightlyLeft {
    padding-left: 1em;
}
