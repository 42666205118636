.schedule__day {
    align-items: center;
    background-color: #eaecef;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: center;
    margin: 4px;
}
.schedule__day--active {
    background-image: linear-gradient(139deg, #6236ff, #987df7);
}
.schedule__day--active p {
    color: var(--primary-color-white);
}
.schedule__day--month {
    color: var(--primary-color-forest);
    font-size: var(--xsmall-text);
    font-weight: 400;
    margin: 0;
}
.schedule__day--num {
    color: var(--primary-color-forest);
    font-family: var(--ion-font-poppins-family);
    font-size: 1.2rem;
    font-weight: var(--font-semi-bold);
    margin: 0;
}
