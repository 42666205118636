ion-grid {
    padding: 0;
}

/* search bar & header */
.genSearch-ionSearchBar {
    --color: var(--primary-color-green);
    --background: #f6f6f6;
    --placeholder-color: var(--primary-color-inactive-gray);
    --icon-color: var(--primary-color-inactive-gray);
    --clear-button-color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
}

.genSearch-header {
    padding-top: 18px;
}

.genSearch-toolbar {
    padding-bottom: 16px;
}

.genSearch__close--btn {
    color: var(--primary-color-green);
    margin-right: 15px;
    margin-left: 0px;
    font-size: 2rem;
}

/* .genSearch__container {
    background-color: #f3f5f7;
    padding-top: 120px;
} */

.genSearch__searchbar {
    background-color: #fff;
    box-shadow: 0px 0px 8px 0px rgba(26, 26, 26, 0.25);
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}
/* .genSearch__searchbar--container {
    align-items: center;
    display: flex;
    padding: 0 30px;
    padding-bottom: 15px;
    padding-top: 40px;
    padding-left: 10px;
    text-align: center;
} */
/* .genSearch__searchbar--input--wrap {
    background-color: #fff;
    border: none;
    position: relative;
    width: 100%;
    z-index: 10;
} */
/* .genSearch__searchbar--input--icon {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    top: 24px;
    left: 20px;
    position: absolute;
} */
/* .genSearch__searchbar--input {
    color: var(--primary-color-green);
    background-color: #f6f6f6;
    border: none;
    border-radius: 8px;
    outline: none;
    padding: 12px 16px;
    padding-left: 30px;
    width: 100%;
} */
.genSearch__searchbar--input::placeholder {
    color: var(--primary-color-inactive-gray);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    padding-left: 5px;
}

/* section title */
.genSearch__sectionTitle {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}
.genSearch__sectionTitle--wrap {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 0 25px;
    text-align: left;
}
.genSearch__sectionTitle--text {
    border-bottom: none;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
}
.genSearch__sectionTitle--skillName {
    color: var(--primary-color-green);
}
.genSearch__sectionTitle--text span {
    color: var(--primary-color-green);
    border-bottom: solid 1px var(--primary-color-green);
}

/* new members list */
.genSearch__newMembers--container {
    background: none;
    background-color: none;
    padding: 0 20px;
    width: 100%;
}
.genSearch__newMembers--list {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    position: relative;
    width: 100%;
}
.genSearch__newMembers--item {
    align-items: flex-start;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    height: 250px;
    flex-direction: column;
    justify-content: flex-end;
    margin: 5px;
    padding: 12px 16px;
    width: 400px;
    max-width: 300px;
}
.genSearch__newMembers--name {
    color: #fff;
    border-bottom: none;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    margin: 0;
    margin-bottom: 2px;
    padding: 0;
}
.genSearch__newMembers--text {
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: x-small;
    font-weight: var(--font-semi-bold);
    margin: 0;
    margin-bottom: 2px;
    padding: 0;
}
.genSearch__newMembers--reviews {
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: x-small;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

/* nearby members */
.genSearch__nearbyMembers--container {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 20px 20px 0px 10px;
    width: 100%;
}
.genSearch__nearbyMembers--title {
    border-bottom: none;
    font-family: var(--ion-font-poppins-family);
    font-weight: 700;
    margin: 0;
    padding: 0;
}
.genSearch__nearbyMembers--title span {
    color: var(--primary-color-green);
    border-bottom: solid 1px var(--primary-color-green);
}

.genSearch__nearbyMembers--text {
    border-bottom: solid 1px var(--primary-color-green);
    color: var(--primary-color-green);
    display: inline-block;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: 700;
    margin: 0;
    padding: 0;
}
.genSearch__nearbyMembers--btn {
    color: var(--primary-color-green);
    border-radius: 8px;
    background-color: #f3f5f7;
}

/* members nearby item */
.nearbyMember__row {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    text-align: center;
}
.nearbyMember__row:last-child {
    padding-bottom: 10px;
}

.nearbyMember {
    align-items: flex-start;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    position: relative;
}

.nearbyMember__info {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.nearbyMember__img {
    border: solid 2px var(--primary-color-white);
    height: auto;
    width: 100%;
}
.nearbyMember__img--wrap {
    height: auto;
    padding-left: 10px;
    position: absolute;
    left: -10px;
    top: -50px;
    width: 75px;
}
.nearbyMember__info--name {
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 0;
}
.nearbyMember__info--reviews {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: 0.75rem;
    margin: 0;
}
.nearbyMember__skills--wrap {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
}
.nearbyMember__skill {
    background-color: #fff;
    border-radius: 8px;
    color: var(--primary-color-black);
    font-size: x-small;
    height: 25px;
}
