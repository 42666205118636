ion-grid {
    padding: 0;
}

/* taskDetails header */
.tpDetails__header {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.tpDetails__header--container {
    align-items: center;
    background: var(--primary-linear-gradient-v2);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 100px;
    padding-bottom: 30px;
    position: relative;
}

.tpDetails__header--strokes {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
.tpDetails__back--btn {
    position: absolute;
    left: 0;
    top: 8%;
    color: #fff;
}
.tpDetails__back--btn--icon {
    font-size: 2rem;
}

.requestDetails__edit--btn {
    border-bottom: solid 1px #fff;
    color: #fff;
    display: inline-block;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
}
.requestDetails__edit--location--btn {
    border-bottom: solid 1px var(--primary-color-black);
    color: var(--primary-color-black);
    display: inline-block;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
}

/* taskDetails description */
.tpDetails__header--desc--wrap {
    padding: 0 15px;
}
.tpDetails__header--desc--taskName {
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-bold);
    padding: 0;
    margin: 0;
}
.tpDetails__header--desc--taskDescr {
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    font-weight: var(--font-regular);
    padding: 0;
}
.tpDetails__header--desc--user {
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-bold);
    /*padding: 0;
    padding-left: 10px;*/
}
.tpDetails__header--descr--img {
    border-radius: 12px;
    border: solid 4px #fff;
    overflow: hidden;
    height: 50px;
    width: 50px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    margin-left: 0%;
    margin-right: 10px;
}
.tpDetails__header--img--wrap {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 0 15px;
    padding-bottom: 75px;
}

/* taskDetails skills list */
.tpDetails__skills {
    background-color: #fff;
    /* margin-bottom: 30px; */
}
.tpDetails__skills--container {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -80px;
    padding: 0 15px;
    text-align: left;
}
.tpDetails__skills--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
}

/* task posted skills */
.tpSkills__container {
    padding: 0 15px;
    padding-bottom: 30px;
    width: 100%;
}

.tpSkills__row {
    background-color: #f3f5f7;
    border-radius: 15px;
    width: 100%;
}

.tpSkills__col {
    align-items: flex-start;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 10px;
}

.tpSkills__content {
    align-items: flex-start;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-align: left;
}

.tpSkills__content--skill {
    color: black;
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}

.tpSkills__content--completed {
    color: var(--primary-color-purple);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
}
.tpSkills__content--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}

.tpSkills__request--btn {
    align-items: center;
    background-color: #fff;
    border-left: solid 1px #f3f5f7;
    border-radius: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: var(--primary-color-dark-green);
    display: flex;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    height: 100%;
    margin: 0;
    outline: none;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    text-align: center;
    width: 100%;
    -webkit-border-radius: none;
    -moz-border-radius: none;
    -ms-border-radius: none;
    -o-border-radius: none;
}

.tpSkills__request--btn--text,
.tpSkills__request--btn--name {
    margin: 0;
    padding: 0;
}

.tpSkills__btn--text {
    color: var(--primary-color-green);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}

.tpSkills__btn--text--accepted {
    color: var(--primary-color-purple);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}

.tpSkills__btn--text--warning {
    color: var(--primary-color-red);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}
.tpSkills__btn--text--disabled {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--xsmall-text);
    margin: 0;
}

/* tp details participants */
.tpDetails__users--container {
    background-color: #fff;
    width: 100%;
}
.tpDetails__users {
    align-items: center;
    background-color: #f3f5f7;
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 12px 15px;
    width: 100%;
}

.tpDetails__user {
    align-items: center;
    background-color: #fff;
    border: solid 4px #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
}
.tpDetails__user--img {
    display: flex;
    border-radius: 15px;
    height: 65px;
    width: 65px;
    overflow: hidden;
}
.tpDetails__user--name {
    margin: 0;
    padding: 5px 0;
}

/* taskDetails location */
.tpDetails__location {
    padding: 12px 15px;
    padding-bottom: 30px;
    width: 100%;
}

.tpDetails__map--wrap {
    background-color: #f3f5f7;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 30px 10px;
    margin-bottom: 10px;
    width: 100%;
}

.tpDetails__location--title {
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
}

.tpDetails__location--text {
    color: var(--primary-color-light-white);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-regular);
    font-size: var(--xsmall-text);
    padding: 0;
    margin: 0;
}

/* sliding item */
.tpSkills__slide {
    align-items: flex-start;
    background-color: #f3f5f7; /* zeplin container color */
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    z-index: 10;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
