ion-grid {
    padding: 0;
}

.phoneVf__grid {
    /* stretches screen to 100% */
    background-color: #fff;
}
.phoneVf__row {
    /* stretches screen to 100% */
    height: 100vh;
    width: 100%;
}
.phoneVf__container {
    /* centers all content */
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}
.phoneVf__back--btn {
    position: absolute;
    left: 11%;
    top: 5%;
    color: black;
}

/* absolute position images */
.phoneVf__strokes--top {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    -webkit-filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
}
.phoneVf__strokes--bottom {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(0deg)
        brightness(0%) contrast(119%);
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
}

/* screen content */
.phoneVf__content--container {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
}
.phoneVf__content--img {
    border-radius: 15px;
    border: solid 4px var(--primary-color-white);
    box-shadow: 0px 0px 10px 0px rgba(26, 26, 26, 1);
    height: 75px;
    overflow: hidden;
    width: 75px;
    z-index: 10;
}

.phoneVf__content--title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: 800;
    padding: 0;
    text-align: center;
}
.phoneVf__content--title span {
    /* color: var(--primary-color-green); */
    color: #10b080;
}
.phoneVf__content--text {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular);
    margin: 0;
    padding: 0 10px;
    padding-bottom: 10px;
    text-align: center;
}

.phoneVf__content--btn {
    background: var(--primary-linear-gradient);
    border-radius: 8px;
    color: #fff;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    width: 70%;
}

/* phoneNumInput input styles */
.phoneVf__input--container {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 120px;
    max-height: 120px;
    width: 100%;
}
.phoneVf__input--wrap {
    position: relative;
}
.phoneVf__input {
    background: none;
    background-color: none;
    border: none;
    border-radius: 0px;
    border-bottom: solid 1px var(--primary-color-inactive-gray);
    color: var(--primary-color-black);
    font-size: var(--small-text);
    font-family: var(--ion-font-poppins-family);
    outline: none;
    letter-spacing: 8px;
    margin-bottom: 20px;
    padding: 5px 16px;
    text-align: center;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}
.phoneVf__input::placeholder {
    background: none;
    color: var(--primary-color-inactive-gray);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    text-align: center;
    letter-spacing: 0;
}

/* ! error handling */
.phoneVf__input--error {
    border-bottom: solid 1px var(--primary-color-red);
}
.phoneVf__error {
    color: var(--primary-color-red);
    font-size: var(--xsmall-text);
    font-weight: 600;
}
.phoneVf__error--span {
    color: var(--primary-color-red);
    font-size: var(--xsmall-text);
    font-weight: 300;
}
