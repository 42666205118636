.gratitude_pot_img {
    width: 20%;
    margin: 0;
}

.paymentMethodInnerContainer {
    background-color: white;
    border-radius: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 5px;
}

.cardIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    flex: 1 auto 1;
}

.backgroundContainer {
    margin: 15px;
    border-radius: 15px;
    background-color: #f3f5f7;
}

.paymentMethodNumber {
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-regular);
    text-align: right;
}

.paymentMethodName {
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-medium);
    white-space: nowrap;
    text-transform: capitalize;
}

.gratitudeCardInnerContainer {
    background-color: white;
}

.setup_heading {
    width: 100%;
    text-align: center;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.75px;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    color: var(--primary-color-black);
    margin-bottom: 0px;
}

.setup_text {
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: -0.41px;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    color: var(--primary-color-black);
}
.setup_tipping {
    background: rgb(155, 102, 255);
    background: linear-gradient(
        180deg,
        rgba(155, 102, 255, 1) 0%,
        rgba(98, 54, 255, 1) 98%
    );
}
.disclaimer_text {
    font-size: 14px;
    color: #aab0b7;
    text-align: center;
    font-family: var(--ion-font-poppins-family);
    line-height: 21px;
    letter-spacing: -0.25px;
    padding: 0px 15px;
}

.skip_button {
    color: var(--primary-color-purple);
    width: 100%;
    text-align: center;
    line-height: 20px;
    letter-spacing: -0.25px;
    font-weight: var(--font-semi-bold);
    padding: 20px 0px;
}

.gratitude_card_container {
    margin: 15px;
    padding: 15px;
    background-color: white;
    border-radius: 16px;
}
.gratitude_card_row {
    width: 100%;
    gap: 10px;
}

.option_selection {
    background-color: #f3f5f7;
    text-align: center;
    color: #10b080;
    font-size: 32px;
    font-weight: var(--font-bold);
    border-radius: 16px;
    padding: 10px;
}
.custom_input_field {
    background-color: white;
    border-radius: 10px;
    height: 100%;
    width: 100%;
}
.custom_input_field ::placeholder {
    color: #f3f5f7;
}
.or_text {
    color: #dadbdc;
    width: 100%;
    text-align: center;
}
.active {
    background-color: #10b080;
    color: #f3f5f7;
}
.error_message {
    color: red;
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.summary_container {
    font-size: 16px;
    line-height: 34px;
    letter-spacing: -0.44px;
    color: var(--primary-color-black);
}

.line_item_name {
    text-align: left;
    padding: 0px 15px;
}
.line_item_amount {
    text-align: right;
    padding: 0px 15px;
}
.total_line_item {
    font-size: 24px;
    letter-spacing: -0.75px;
    font-weight: var(--font-bold);
    padding: 0px 15px;
}
.total_line_item_amount {
    font-size: 24px;
    letter-spacing: -0.75px;
    text-align: right;
    padding: 0px 15px;
}

.disabled_grat_pot {
    font-size: 20px;
    letter-spacing: -0.75px;
    text-align: center;
    padding: 0px 15px;
    width: 100%;
    font-weight: var(--font-bold);
    color: #dadbdc;
}
.section-heading {
    font-family: var(--ion-font-poppins-family);
    padding: 0px 15px;
    font-size: 18px;
    margin-bottom: 0px;
    font-weight: 600;
    color: black;
}
.modified-spinner {
    padding-top: 20px;
}
.gratitude-grid-container {
    margin-bottom: 75px;
}
