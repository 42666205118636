.skillCreation__user--img--wrap {
    border-radius: 15px !important;
    height: 75px;
    width: 75px;
    z-index: 10;
}
.skillCreation__user--img {
    border-radius: 15px !important;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 10;
}
