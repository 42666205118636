.add_payment__header--container {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 95px;
    padding-bottom: 30px;
    position: relative;
}

.payment_method__header--img {
    border: solid 6px #fff;
    background-color: #f3f5f7;
    border-radius: 15px;
    height: 100px;
    width: 100px;
    overflow: hidden;
    z-index: 10;
}

.heading {
    font-family: var(--ion-font-poppins-family);
    text-align: center;
    font-weight: var(--font-bold);
    width: 100%;
}

.heading span {
    color: var(--primary-color-green);
    width: 100%;
    text-align: center;
}

.heading_description {
    width: 100%;
    text-align: center;
    font-weight: var(--medium-text);
    font-size: 17px;
}

.form_container {
    width: 90%;
    margin: auto;
    background-color: #f3f5f7;
    border-radius: 24px;
    padding: 5px 10px;
}

.form_element {
    padding: 5px 10px;
    width: 100%;
}

.form_field {
    width: 100%;
    margin: 0;
    padding: 5px;
    border-radius: 16px !important;
    background-color: white;
}

.form_label {
    font-size: 14px;
    margin-top: 0;
}

.form_label span {
    color: #d0011b;
}

.StripeElement {
    padding: 10px;
}
.independent_input {
    padding: 0px 10px !important;
    font-weight: 500;
    opacity: 0.9;
}
.stripe_response_message {
    color: var(--primary-color-green);
    font-size: 14px;
    text-align: center;
    width: 100%;
}
.stripe_page_container {
    font-family: var(--ion-font-poppins-family);
}
.error {
    color: #d0011b;
}
.success_icon {
    font-size: 25px;
    border: 2px solid;
    border-radius: 100%;
    align-self: center;
}
.nav_icons {
    color: black;
    font-size: 2.2rem;
}
