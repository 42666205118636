/* broadcast btn */
.msgBroadcast__btn--container {
    width: 100%;
}
.msgBroadcast__btn--wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
}
.msgBroadcast__btn {
    align-items: center;
    background-color: #fff !important;
    border: solid 2px #f3f5f7 !important;
    border-radius: 8px;
    color: var(--primary-color-dark-green);
    display: flex;
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    margin-top: 10px !important;
    justify-content: space-between;
    width: 100% !important;
}

/* fallback chatroom index message */
.msgFallbackText--wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0;
    text-align: center;
    padding-bottom: 55px;
    width: 100%;
}
.msgFallbackText {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-regular-bold);
    margin: 0;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-left: 25px;
}

/* profile details */
.msgDetails {
    background: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -60px;
    position: relative;
    /* margin-top: -40px; */
    width: 100%;
}

.msgDetails__col--btn {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -25px;
}

.msgDetails__btn {
    background: linear-gradient(106deg, #0091ff, #32c5ff);
    border: none;
    border-radius: 8px;
    height: 45px;
    color: var(--primary-color-white);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--small-text);
    margin-right: 12px;
    width: 180px;
}
.msgDetails__btn--icon {
    font-size: 1rem;
}

.msgDetails__home--btn {
    background-color: #fff;
    border: solid 2px #f3f5f7;
    border-radius: 8px;
    height: 45px;
    color: var(--primary-color-dark-green);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    font-size: var(--xsmall-text);
    margin: 0 8px;
    width: 170px;
}

/* chatrooms */
.msgChatroom__bluedot {
    position: absolute !important;
    bottom: 50%;
    right: 0px;
    width: 12.5px;
    height: 12.5px;
    border: rgb(96, 54, 255) 1px;
    border-radius: 20px;
    background: linear-gradient(
        200deg,
        rgb(155, 102, 255) 0%,
        rgb(96, 54, 255) 100%
    );
    margin-top: 50%;
    margin-left: 50%;
}

.msgChatroom__alert {
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
}
.msgChatroom__alert--btn--delete {
    background-color: red;
    --color: #ff8c8c;
}
.msgChatrooms {
    background: #fff;
    position: relative;
    width: 100%;
}

.msgChatrooms__col--title {
    /* padding: 15px 25px;
    width: 100%; */
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
    padding: 15px 20px;
    margin-top: -10px;
}
.msgChatrooms__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
}

.msgChatrooms__container {
    padding-bottom: 50px;
    width: 100%;
    padding-right: 25px;
}

.msgChatrooms__row {
    width: 100%;
}

/* single chatroom */
.msgChatroom__wrap {
    margin-bottom: 10px;
    width: 100%;
}
.msgChatroom {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 100%;
}
.msgChatroom__img--wrap {
    border-radius: 15px;
    overflow: hidden;
    position: relative;
}
.msgChatroom__img {
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
}
.msgChatroom__icon {
    background-color: #fff;
    border-radius: 100px;
    bottom: 3px;
    position: absolute;
    right: 5px;
}
.msgChatroom__icon--online {
    color: var(--primary-color-green);
}
.msgChatroom__icon--offline {
    color: var(--primary-color-light-white);
}
.msgChatroom__delete--btn {
    background: linear-gradient(106deg, #ff4242, #ff8c8c);
}

.msgChatroom__preview {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.msgChatroom__preview--title {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: var(--font-semi-bold);
    margin: 0;
}
.msgChatroom__preview--text {
    font-family: var(--ion-font-poppins-family);
    font-size: var(--small-text);
    font-weight: 300;
    margin: 0;
}

/* privateRooms */
.msgPrivaterooms {
    background-color: #f6f6f6;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    position: relative;
    width: 100%;
}

.msgPrivaterooms__col--title {
    padding: 15px 25px;
    width: 100%;
}
.msgPrivaterooms__title {
    border-bottom: none;
    color: var(--primary-color-black);
    font-family: var(--ion-font-poppins-family);
    font-weight: var(--font-semi-bold);
    margin: 0;
}

.msgPrivaterooms__container {
    padding: 0 15px;
    padding-bottom: 50px;
    width: 100%;
}

.msgPrivaterooms__row {
    width: 100%;
}
